import { useMsal } from '@azure/msal-react';
import { useContext, useEffect } from 'react';
import { AppContext, AppDispatchContext } from '../context';
import { updateUser } from './util';
import { createUser } from '../API';

export function useInitUser() {
  const dispatchCtx = useContext(AppDispatchContext);
  const ctx = useContext(AppContext);
  const msalInstance = useMsal();
  const account = msalInstance?.instance.getAllAccounts()[0];
  const userId = account?.idTokenClaims?.sub;

  useEffect(() => {
    const initUser = async () => {
      if (!userId) {
        return;
      } else if (!ctx.user) {
        await createUser(userId);
        await updateUser(userId, dispatchCtx.setUser);
      }
    };
    initUser();
  }, [userId, ctx.user, dispatchCtx.setUser]);
}
