import React, { createContext, useReducer } from 'react';
import { User } from 'types';

type SetUserAction = {
  type: 'SET_USER';
  payload: User;
};

type SetTitleAction = {
  type: 'SET_TITLE';
  payload: string;
};

type ClearTitleAction = {
  type: 'CLEAR_TITLE';
};

type SetChallengeSearchAction = {
  type: 'SET_CHALLENGE_QUERY';
  payload: string;
};

type AppAction = SetUserAction | SetTitleAction | ClearTitleAction | SetChallengeSearchAction;

interface AppState {
  user?: User;
  title: string;
  challengeQuery: string;
}

interface AppDispatchState {
  setUser: (user: User) => void;
  setTitle: (title: string) => void;
  clearTitle: () => void;
  setChallengeQuery: (query: string) => void;
}

const initialState: AppState = {
  user: undefined,
  title: '',
  challengeQuery: '',
};

export const AppContext = createContext<AppState>(initialState);
export const AppDispatchContext = createContext<AppDispatchState>({
  setUser() {},
  setTitle() {},
  clearTitle() {},
  setChallengeQuery() {},
});

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_TITLE':
      return {
        ...state,
        title: action.payload,
      };
    case 'CLEAR_TITLE':
      return {
        ...state,
        title: '',
      };
    case 'SET_CHALLENGE_QUERY':
      return {
        ...state,
        challengeQuery: action.payload,
      };
    default:
      return state;
  }
};

export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { children } = props;

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider
        value={{
          setUser: (user) => dispatch({ type: 'SET_USER', payload: user }),
          setTitle: (title) => dispatch({ type: 'SET_TITLE', payload: title }),
          clearTitle: () => dispatch({ type: 'CLEAR_TITLE' }),
          setChallengeQuery: (query) => dispatch({ type: 'SET_CHALLENGE_QUERY', payload: query }),
        }}
      >
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};
