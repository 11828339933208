import ky from 'ky';

import {
  Challenge,
  ChallengeData,
  Player,
  PlayerData,
  Quest,
  QuestData,
  CompletedQuest,
  DeleteCompletion,
  QuestCompletion,
  QuestCompletionData,
  User,
} from 'types';
import configuration from './utils/configuration';
import * as Storage from './utils/local-storage-utils';

/********** AUTH **********/
const { clientId, scopes, teamId, callbackUrl } = configuration.auth;
/*
 * Authorize URL for slack
 * TODO we're now using V1 from the API since golang oauth lib doesn't support V2 slack API!
 */
export const AUTHORIZE_URL = `https://slack.com/oauth/authorize?client_id=${clientId}&scope=${scopes}&team=${teamId}&redirect_uri=${callbackUrl}`;

const api = ky.extend({
  prefixUrl: configuration.apiUrl,
  hooks: {
    afterResponse: [
      (_input, _options, res) => {
        // Save the path + search query if unauthorized &
        // redirect to slack authorization
        if (res.status === 401) {
          Storage.save(Storage.UNAUTHORIZED_PATH, window.location.pathname + window.location.search);
          window.location.replace(AUTHORIZE_URL);
        }
      },
    ],
  },
});
/********** !AUTH **********/

/********** CHALLENGES **********/
export async function createChallenge(challenge: ChallengeData): Promise<Challenge> {
  const resp = await api.post('challenges', {
    json: challenge,
  });
  return (await resp.json()) as Challenge;
}

export async function updateChallenge(challenge: Challenge): Promise<void> {
  await api.put(`challenges/${challenge.id}`, {
    json: challenge,
  });
}

export async function deleteChallenge(challengeId: Challenge['id']): Promise<void> {
  await api.delete(`challenges/${challengeId}`);
}

// TODO: Only use updateChallenge
export async function SetChallengeGoal(challengeId: Challenge['id'], goal: Challenge['goal']): Promise<void> {
  await api.put(`challenges/${challengeId}`, {
    json: {
      goal,
    },
  });
}

/**
 * Get challenges quests completions
 */
export async function getQuestCompletions(challengeId: Challenge['id']): Promise<CompletedQuest[]> {
  const resp = await api.get(`challenges/${challengeId}/completed-quests`);
  return (await resp.json()) as CompletedQuest[];
}

async function getChallengeById(challengeId: Challenge['id']): Promise<Challenge> {
  const resp = await api.get(`challenges/${challengeId}`);
  return (await resp.json()) as Challenge;
}

export interface GetAllChallengesProps {
  page: number;
  pageSize: number;
  query: string;
  userId: string | undefined;
}

export async function getAllChallenges({ page, pageSize, query, userId }: GetAllChallengesProps): Promise<Challenge[]> {
  if (userId === undefined) {
    return Promise.resolve([]);
  }

  const params = new URLSearchParams({
    page: page.toString(),
    pageSize: pageSize.toString(),
    query,
    userId,
  });
  const resp = await api.get(`challenges?` + params.toString());
  return (await resp.json()) as Challenge[];
}
/********** !CHALLENGES **********/

/********** PLAYERS **********/
export interface CreatePlayerProps {
  challengeId: Challenge['id'];
  player: PlayerData;
}

export async function createPlayer({ challengeId, player }: CreatePlayerProps): Promise<Player> {
  const resp = await api.post(`challenges/${challengeId}/players`, {
    json: {
      name: player.name,
      avatar: player.avatar,
    },
  });
  return (await resp.json()) as Player;
}

export async function getPlayers(challengeId: Challenge['id']): Promise<Player[]> {
  const resp = await api.get(`challenges/${challengeId}/players`);
  return (await resp.json()) as Player[];
}

export interface UpdatePlayerProps {
  playerId: Player['id'];
  player: PlayerData;
}

export async function updatePlayer({ playerId, player }: UpdatePlayerProps): Promise<void> {
  await api.put(`players/${playerId}`, {
    json: {
      name: player.name,
      avatar: player.avatar,
    },
  });
}

export async function deletePlayer(playerId: Player['id']): Promise<void> {
  await api.delete(`players/${playerId}`);
}
/********** !PLAYERS **********/

/********** QUESTS **********/

export interface CreateQuestProps {
  challengeId: Challenge['id'];
  quest: QuestData;
}

export async function createQuest({ challengeId, quest }: CreateQuestProps): Promise<Quest> {
  const resp = await api.post(`challenges/${challengeId}/quests`, {
    json: quest,
  });
  return (await resp.json()) as Quest;
}

export interface UpdateQuestProps {
  quest: Quest;
}

export async function updateQuest({ quest }: UpdateQuestProps): Promise<void> {
  await api.put(`quests/${quest.id}`, {
    json: quest,
  });
}

export interface ReorderQuestsProps {
  id: Quest['id'];
  newPosition: number;
}

export async function reorderQuests(quest: ReorderQuestsProps): Promise<Quest[]> {
  const response = await api
    .put('quests/reorder', {
      json: quest,
    })
    .json<Quest[]>();

  return response;
}

export async function getQuests(challengeId: Challenge['id']): Promise<Quest[]> {
  const resp = await api.get(`challenges/${challengeId}/quests`);
  const quests = (await resp.json()) as Quest[];

  if (quests) {
    // Sort quests from lowest to highest points
    quests.sort((a, b) => a.points - b.points);
    // Sort quests by active state (False last)
    quests.sort((a, b) => {
      if (a.active === b.active) {
        return 0;
      }
      if (a.active) {
        return -1;
      }
      return 1;
    });
  }
  return quests;
}

export interface RemoveQuestProps {
  questId: Quest['id'];
}
export async function removeQuest({ questId }: RemoveQuestProps): Promise<void> {
  await api.delete(`quests/${questId}`);
}

export interface CompleteQuestProps {
  quest: Quest;
  playerId: Player['id'];
}

export async function createQuestCompletion({ quest, playerId }: CompleteQuestProps): Promise<QuestCompletion> {
  const resp = await api.post(`quests/${quest.id}/complete`, {
    json: {
      player: playerId,
    } as QuestCompletionData,
  });
  return (await resp.json()) as QuestCompletion;
}

export interface DeleteCompletionProps {
  questCompletionId: number;
  quest: Quest;
  playerId: Player['id'];
}

export async function deleteQuestCompletion({
  // TODO: Refactor so that react query has needed parameters for cache updates using this API.
  questCompletionId,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  quest,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  playerId,
}: DeleteCompletionProps): Promise<DeleteCompletion> {
  const resp = await api.delete(`completions/${questCompletionId}`);
  return (await resp.json()) as DeleteCompletion;
}
/********** !QUESTS **********/

/********** USERS **********/
export async function createUser(userId: User['id']): Promise<void> {
  await api.post(`users/${userId}`);
}

export async function getUser(userId: User['id']): Promise<User> {
  const response = await api.get(`users/${userId}`);
  return response.json();
}

export async function addFavorite(userId: User['id'], challengeId: Challenge['id']): Promise<Response> {
  return api.post(`users/${userId}/favorites/${challengeId}`);
}

export async function removeFavorite(userId: User['id'], challengeId: Challenge['id']): Promise<Response> {
  return api.delete(`users/${userId}/favorites/${challengeId}`);
}
/********** !USERS **********/

/***** API call bundles *****/
export async function getChallenge(challengeId: Challenge['id']): Promise<Challenge> {
  const [challenge, players, quests] = await Promise.all([
    getChallengeById(challengeId),
    getPlayers(challengeId),
    getQuests(challengeId),
  ]);
  challenge.players = players;
  challenge.quests = quests;
  return challenge;
}
