import React, { ReactNode } from 'react';
import { Box, Text } from 'grommet';

interface ErrorMessageProps {
  color: string;
  children: ReactNode;
}

export const ErrorMessage = ({ children, color }: ErrorMessageProps) => {
  return (
    <Box margin={{ bottom: 'xsmall', top: 'xsmall' }} height={'5px'}>
      <Text color={color} size="small" style={{ fontStyle: 'italic', fontWeight: 'lighter' }}>
        {children}
      </Text>
    </Box>
  );
};
