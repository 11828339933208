import React, { FC } from 'react';
import { Text, Box } from 'grommet';
import { tada, headShake } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { Run } from 'grommet-icons';
import AppTheme from 'themes/AppTheme';
import { ReactComponent as HorseRider } from 'img/horse-rider.svg';

const TadaAnimation = keyframes`${tada}`;
const ShakeAnimation = keyframes`${headShake}`;

const LoadingAnimation = styled(Box)`
  animation: 2s ${TadaAnimation} infinite;
`;

const FetchFailAnimation = styled(Box)`
  animation: 1s ${ShakeAnimation};
`;

interface HorseRiderSpinnerProps {
  iconColor: string;
  textColor: string;
  loadingText?: string;
}

/**
 * Common running man animated loader
 *
 * @param {HorseRiderSpinnerProps} props
 * @returns
 */
export const HorseRiderSpinner: FC<HorseRiderSpinnerProps> = (props: HorseRiderSpinnerProps) => {
  const { iconColor, textColor, loadingText } = props;
  return (
    <>
      <LoadingAnimation>
        <HorseRider fill={iconColor} height={'100px'} />
      </LoadingAnimation>
      <Text weight="bold" color={textColor}>
        {loadingText || 'Loading...'}
      </Text>
    </>
  );
};

interface HorseRiderLoaderProps {
  loadingText?: string;
  isError?: boolean;
  errorText?: string;
}
export const RunningManLoader: FC<HorseRiderLoaderProps> = (props: HorseRiderLoaderProps) => {
  const { loadingText, isError, errorText } = props;
  return (
    <Box fill responsive flex justify="center" background="nl-lilac-200" data-testid="running-man-loader">
      <Box
        fill="horizontal"
        align="center"
        justify="center"
        pad={{ vertical: 'small', horizontal: 'large' }}
        background="brand"
        border={{ color: 'white', size: '10px', side: 'horizontal' }}
      >
        {isError ? (
          <>
            <FetchFailAnimation>
              <Run size="large" color={AppTheme.global.colors['nl-ash-0']} />
            </FetchFailAnimation>
            <Text weight="bold" color="nl-ash-0">
              {errorText || 'Failed to fetch ...'}
            </Text>
          </>
        ) : (
          <HorseRiderSpinner
            textColor={AppTheme.global.colors['nl-ash-0']}
            iconColor={AppTheme.global.colors['nl-ash-0']}
            loadingText={loadingText}
          />
        )}
      </Box>
    </Box>
  );
};
