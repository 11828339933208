import { useContext, useState } from 'react';
import { addFavorite, removeFavorite } from 'API';
import { AppContext, AppDispatchContext } from 'context';
import { updateUser } from 'hooks/util';
import { Challenge } from 'types';

export function useFavorites() {
  const appCtx = useContext(AppContext);
  const dispatchCtx = useContext(AppDispatchContext);
  const userId = appCtx.user?.id;
  const favorites = appCtx.user?.favorites;
  const [isLoading, setIsLoading] = useState(false);

  const addFavoriteToUser = async (challengeId: Challenge['id']) => {
    if (userId) {
      setIsLoading(true);
      await addFavorite(userId, challengeId);
      await updateUser(userId, dispatchCtx.setUser);
      setIsLoading(false);
    }
  };

  const removeFavoriteFromUser = async (challengeId: Challenge['id']) => {
    if (userId) {
      setIsLoading(true);
      await removeFavorite(userId, challengeId);
      await updateUser(userId, dispatchCtx.setUser);
      setIsLoading(false);
    }
  };

  return { addFavoriteToUser, removeFavoriteFromUser, favorites, isLoading };
}
