import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePlayerProps, createPlayer } from '../API';
import { Player } from 'types';
import { playersQueryKey } from './useGetPlayers';
import { challengeQueryKey } from './useGetChallenge';

export function useCreatePlayer(onError?) {
  const queryClient = useQueryClient();

  return useMutation<Player, Error, CreatePlayerProps>({
    mutationFn: createPlayer,
    onSuccess: () => {
      queryClient.invalidateQueries([playersQueryKey]);
      queryClient.invalidateQueries([challengeQueryKey]);
    },
    onError,
  });
}
