import * as React from 'react';
import styled from 'styled-components';
import AppTheme from 'themes/AppTheme';

interface ProgressBarProps {
  bgcolor: string;
  progression: number;
  height?: string;
  border?: string;
  hasCompleted?: boolean;
  borderColor?: string;
}

const defaultProps = {
  height: '20px',
  border: '2px solid',
  borderColor: '#fff',
  hasCompleted: false,
};

interface StyledContainerProps {
  height: string | undefined;
  border: string | undefined;
  borderColor: string | undefined;
}

const StyledContainer = styled.div<StyledContainerProps>`
  height: ${(props) => props.height};
  width: 100%;
  background-color: ${AppTheme.global.colors['nl-lilac-100']};
  border: ${(props) => props.border};
  border-color: ${(props) => props.borderColor};
`;

interface StyledFillerProps {
  progression: number;
  bgcolor: string | undefined;
  border: string | undefined;
  borderColor: string | undefined;
}

const StyledFiller = styled.div<StyledFillerProps>`
  height: 100%;
  width: ${(props) => `${props.progression}%`};
  background-color: ${(props) => props.bgcolor};
  border-right: ${(props) => props.border};
  border-color: ${(props) => props.borderColor};
  text-align: right;
  transition: width 0.7s ease-in;
`;

export const ProgressBar = ({ bgcolor, progression, height, border, hasCompleted, borderColor }: ProgressBarProps) => {
  const bgColorChecked = hasCompleted ? '#F5D357' : bgcolor;
  const borderColorChecked = hasCompleted ? '#E8BA76' : borderColor;
  return (
    <StyledContainer height={height} border={border} borderColor={borderColorChecked}>
      <StyledFiller
        progression={progression}
        bgcolor={bgColorChecked}
        border={border}
        borderColor={borderColorChecked}
      />
    </StyledContainer>
  );
};

ProgressBar.defaultProps = defaultProps;
