import { Box, Button, Calendar, Form, FormField, Grommet, ResponsiveContext, Text, TextInput } from 'grommet';
import { deepMerge } from 'grommet/utils';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as HorseRider } from 'img/horse-rider.svg';
import { AppDispatchContext, ToastContext } from '../context';
import { useCreateChallenge } from '../hooks/useCreateChallenge';
import AppTheme from '../themes/AppTheme';

const CreateChallengeTheme = deepMerge(AppTheme, {
  global: {
    borderSize: {
      xsmall: '2px',
    },
  },
});

interface FormCalendarProps {
  value: any;
  onChange(event: any): void;
}

function formCalendar({ value, onChange }: FormCalendarProps) {
  const onSelect = (dates) => onChange({ target: { value: dates } });

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <Calendar
          animate
          showAdjacentDays={false}
          firstDayOfWeek={1}
          daysOfWeek={true}
          alignSelf="center"
          size={responsive === 'small' ? 'small' : 'medium'}
          onSelect={onSelect}
          dates={value}
          range
        />
      )}
    </ResponsiveContext.Consumer>
  );
}

export default function CreateChallenge() {
  const { mutateAsync, isLoading } = useCreateChallenge();

  const [formValid, setFormValid] = useState(false);

  const dispatchCtx = useContext(AppDispatchContext);
  const toastCtx = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    dispatchCtx.setTitle('Create challenge');
    document.title = 'Create challenge | ChallengeApp';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormChange(event: any) {
    if (!event.hasOwnProperty('title') || !event.hasOwnProperty('daterange')) {
      return setFormValid(false);
    }
    if (event.title === '' || (event.daterange?.[0]?.length ?? 0) === 0) {
      return setFormValid(false);
    }
    return setFormValid(true);
  }

  function submitForm(event: any) {
    if (formValid) {
      mutateAsync({
        title: event.value.title,
        description: event.value.description,
        goal: 100,
        start: typeof event.value.daterange === 'string' ? event.value.daterange : event.value.daterange[0][0],
        end: typeof event.value.daterange === 'string' ? event.value.daterange : event.value.daterange[0][1],
      })
        .then((challenge) => {
          navigate(`/challenge/${challenge.id}`);
        })
        .catch((err) => toastCtx.setError(err));
    }
  }

  return (
    <Grommet theme={CreateChallengeTheme}>
      <Box margin={{ vertical: 'medium' }} direction="row" fill flex>
        <Box flex align="center" justify="center">
          <Form onSubmit={submitForm} onChange={onFormChange}>
            <FormField
              label={
                <span>
                  Title <span style={{ color: 'red' }}>*</span>
                </span>
              }
              name="title"
              required
            >
              <TextInput data-testid="create-challenge-title" name="title" />
            </FormField>
            <FormField label="Description" name="description">
              <TextInput data-testid="create-challenge-description" name="description" />
            </FormField>
            <FormField
              label={
                <span>
                  Duration <span style={{ color: 'red' }}>*</span>
                </span>
              }
              help="Duration can be a single day or multiple days!"
              name="daterange"
              value={Array[2]}
              component={formCalendar}
              required
            />
            <Box direction="row" justify="center" margin={{ top: 'medium', bottom: 'medium' }}>
              <Button
                a11yTitle=""
                icon={<HorseRider fill={AppTheme.global.colors['nl-ash-0']} height={'36px'} />}
                color="brand"
                type="submit"
                label={
                  <Text size="large" weight="bold" color="nl-ash-0">
                    {isLoading ? 'Creating...' : 'Create challenge'}
                  </Text>
                }
                primary
                disabled={isLoading || !formValid}
                data-testid="create-challenge-btn"
              />
            </Box>
          </Form>
        </Box>
      </Box>
    </Grommet>
  );
}
