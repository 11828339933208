import React from 'react';
import { Box } from 'grommet';

interface DividerProps {
  color?: string;
}

export const Divider = ({ color = 'nl-lilac-600' }: DividerProps) => {
  return <Box fill="horizontal" height="2px" background={color} />;
};
