import React, { ReactNode } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { CSSProperties } from 'styled-components';

export interface ModalTopIconHolderProps {
  children: ReactNode;
  style?: CSSProperties;
}

export function ModalTopIconHolder({ children, style }: ModalTopIconHolderProps) {
  return (
    <Box style={{ marginBottom: '-48px', zIndex: 10, ...style }}>
      <ResponsiveContext.Consumer>
        {(responsive) =>
          responsive !== 'small' ? (
            <Box
              round="large"
              background="white"
              elevation="medium"
              height="xsmall"
              width="xsmall"
              alignSelf="center"
              justify="center"
              align="center"
            >
              {children}
            </Box>
          ) : (
            <Box
              round="xlarge"
              background="white"
              elevation="medium"
              height="xsmall"
              width="xsmall"
              alignSelf="center"
              justify="center"
              align="center"
              margin={{ bottom: 'medium' }}
            >
              {children}
            </Box>
          )
        }
      </ResponsiveContext.Consumer>
    </Box>
  );
}
