import * as React from 'react';
import { Grommet, ResponsiveContext } from 'grommet';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { msalConfig } from 'auth/authconfig';
import configuration from 'utils/configuration';

import { AppContextProvider, ToastContextProvider, ChallengeContextProvider } from './context';
import AppRouter from './routes';
import AppTheme from 'themes/AppTheme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

interface Props {
  children: React.ReactNode;
}

function MsalWrapper({ children }: Props) {
  if (configuration.disableAuth) {
    return <>{children};</>;
  }
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

function App() {
  return (
    <MsalWrapper>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <ToastContextProvider>
            <ChallengeContextProvider>
              <Grommet theme={AppTheme} full style={{ overflow: 'visible', width: '100%' }}>
                <ResponsiveContext.Consumer>{() => <AppRouter />}</ResponsiveContext.Consumer>
              </Grommet>
            </ChallengeContextProvider>
          </ToastContextProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </MsalWrapper>
  );
}

export default App;
