import { Box, Button, ButtonProps, Text } from 'grommet';
import * as React from 'react';

export interface ModalButtonProps extends ButtonProps {
  content: any;
  onClick(event: any): void;
  a11yTitle?: string;
}

export const ModalButton = ({
  content,
  icon,
  disabled,
  active,
  type,
  margin,
  onClick,
  a11yTitle,
}: ModalButtonProps) => {
  return (
    <Button
      fill
      style={{ minHeight: '50px' }}
      a11yTitle={a11yTitle}
      primary
      icon={icon}
      label={
        <Box height="100%" justify="center" alignContent="center">
          <Text
            alignSelf="center"
            style={{ lineHeight: '15px', userSelect: 'none' }}
            data-testid={`${a11yTitle || 'modal-button'}-content`}
            size="small"
          >
            {content}
          </Text>
        </Box>
      }
      disabled={disabled}
      active={active}
      type={type}
      margin={margin}
      alignSelf="center"
      onClick={onClick}
    />
  );
};
