import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompleteQuestProps, createQuestCompletion } from '../API';
import { Challenge, CompletedQuest, QuestCompletion } from 'types';
import { challengeQueryKey } from './useGetChallenge';
import { completedQuestsQueryKey } from './useGetQuestCompletions';
import { updatePointsForPlayerInArray } from './util';
import moment, { now } from 'moment';

const placeHolderID = 999999999;

export function useCreateQuestCompletion() {
  const queryClient = useQueryClient();

  return useMutation<QuestCompletion, Error, CompleteQuestProps, () => void>({
    mutationFn: createQuestCompletion,
    onMutate: async (newCompletion: CompleteQuestProps) => {
      await queryClient.cancelQueries([completedQuestsQueryKey]);
      await queryClient.cancelQueries([challengeQueryKey]);

      const snapshotCompletions = queryClient.getQueryData<Array<CompletedQuest>>([completedQuestsQueryKey], {
        exact: false,
      });
      const snapshotChallenge = queryClient.getQueryData<Challenge>([challengeQueryKey], { exact: false });

      if (snapshotCompletions && snapshotChallenge) {
        let updatedCompletions = [...snapshotCompletions];
        updatedCompletions.push({
          id: placeHolderID,
          player: newCompletion.playerId,
          quest: newCompletion.quest.id,
          completed: moment(now()).toDate(),
        });
        queryClient.setQueryData<Array<CompletedQuest>>(
          [completedQuestsQueryKey, snapshotChallenge.id],
          updatedCompletions,
        );
        queryClient.setQueryData<Challenge>([challengeQueryKey, snapshotChallenge.id], {
          ...snapshotChallenge,
          players: updatePointsForPlayerInArray(
            snapshotChallenge.players,
            newCompletion.playerId,
            newCompletion.quest.points,
          ),
        });
        return () => {
          queryClient.setQueryData<Array<CompletedQuest>>(
            [challengeQueryKey, snapshotChallenge.id],
            snapshotCompletions,
          );
          queryClient.setQueryData<Challenge>([challengeQueryKey, snapshotChallenge.id], snapshotChallenge);
        };
      }
    },
    onError: (error, variables, rollback) => {
      if (rollback) {
        rollback();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([challengeQueryKey]);
      queryClient.invalidateQueries({ queryKey: [completedQuestsQueryKey], refetchType: 'all' });
    },
  });
}
