import React, { createContext, useReducer } from 'react';
import { Challenge } from 'types';

/**
 * Valid actions for challenge context
 */
const SET_CHALLENGE = 'SET_CHALLENGE';
const CLEAR_CHALLENGE = 'CLEAR_CHALLENGE';

/**
 * Challenge state
 *
 * @interface ChallengeState
 */
interface ChallengeState {
  challenge?: Challenge;
  setChallenge: (challenge: Challenge) => void;
  clearChallenge: () => void;
}

/**
 * Challenge context
 */
export const ChallengeContext = createContext({} as ChallengeState);

/**
 * Reducer for challenge state
 *
 * @param {ChallengeState} state
 * @param {({ type: string; payload: string | undefined })} action
 * @returns
 */
const challengeReducer = (state: ChallengeState, action: { type: string; payload: Challenge | null }) => {
  switch (action.type) {
    case SET_CHALLENGE:
      return {
        ...state,
        challenge: action.payload,
      } as ChallengeState;
    case CLEAR_CHALLENGE:
      return {
        ...state,
        challenge: undefined,
      } as ChallengeState;
    default:
      return state;
  }
};

/**
 * Challenge context provider
 *
 * @param {{ [x: string]: any }} { ...props }
 * @returns
 */
export const ChallengeContextProvider = ({ ...props }: { [x: string]: any }) => {
  const [state, dispatch] = useReducer(challengeReducer, {} as ChallengeState);
  const { children } = props;
  return (
    <ChallengeContext.Provider
      value={{
        ...state,
        setChallenge: (challenge) => dispatch({ type: SET_CHALLENGE, payload: challenge }),
        clearChallenge: () => dispatch({ type: CLEAR_CHALLENGE, payload: null }),
      }}
    >
      {children}
    </ChallengeContext.Provider>
  );
};
