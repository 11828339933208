import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteChallenge } from '../API';
import { challengeQueryKey } from './useGetChallenge';

export function useDeleteChallenge() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>({
    mutationFn: deleteChallenge,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [challengeQueryKey] });
    },
  });
}
