import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, TextInput, Heading } from 'grommet';
import { Challenge, Quest } from 'types';
import { ModalDivider, ModalButton } from 'components/modal';
import { ToastContext } from '../context';
import { ErrorMessage } from 'components/error';
import { useUpdateQuest } from 'hooks/useUpdateQuest';
import { useCreateQuest } from 'hooks/useCreateQuest';
import { ViewState } from './QuestModal';
import { Info } from 'grommet-icons';
import AppTheme from 'themes/AppTheme';

const maxTitleLength = 30;
const pointsCharacterLimit = 8;

interface QuestFormProps {
  selectedQuest?: Quest;
  quests: Quest[];
  challengeId: Challenge['id'];
  loadForm: (state: ViewState) => void;
  closeForm: () => void;
}

export default function QuestForm({ selectedQuest, quests, challengeId, loadForm, closeForm }: QuestFormProps) {
  const [modalTitle, setModalTitle] = useState('Add quest');
  const [title, setTitle] = useState<Quest['title']>();
  const [titleFormFieldInvalid, setTitleFormFieldInvalid] = useState<boolean>();
  const [titleErrorMessage, setTitleErrorMessage] = useState<string>();
  const [points, setPoints] = useState<Quest['points']>();
  const [pointsFormFieldInvalid, setPointsFormFieldInvalid] = useState<boolean>();
  const [goal, setGoal] = useState<Quest['goal']>();

  const toastCtx = useContext(ToastContext);

  const onError = (error: Error) => toastCtx.setError(error);
  const { isLoading: isUpdatingQuest, mutateAsync: updateQuest } = useUpdateQuest(onError);
  const { isLoading: isCreatingQuest, mutateAsync: createQuest } = useCreateQuest(onError);

  const onSubmit = async () => {
    if (!title || !points) {
      return;
    }
    if (!selectedQuest) {
      loadForm(ViewState.QuestIsBeingAddedView);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1s to allow the user to see the loading panel
      await createQuest({
        challengeId: challengeId,
        quest: { title, points, active: true, goal, repeats: true },
      });
      closeForm();
    } else {
      loadForm(ViewState.QuestIsBeingEditedView);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1s to allow the user to see the loading panel
      await updateQuest({
        quest: {
          id: selectedQuest.id,
          title,
          points,
          active: true,
          goal,
          repeats: true,
        },
      });
      closeForm();
    }
  };

  useEffect(() => {
    function isTitleFormFieldInvalid() {
      if (selectedQuest?.title === title) {
        setTitleErrorMessage('');
        return false;
      }
      if (quests?.find((quest) => quest.title.toLowerCase() === title?.toLowerCase())) {
        setTitleErrorMessage(`Task name '${title}' already used!`);
        return true;
      }
      if (title?.length === 0) {
        setTitleErrorMessage('');
        return true;
      }
      if (title?.length === maxTitleLength) {
        setTitleErrorMessage(`Maximum character limit of ${maxTitleLength} reached`);
        return true;
      }

      setTitleErrorMessage('');
      return false;
    }

    setTitleFormFieldInvalid(isTitleFormFieldInvalid());
  }, [title, selectedQuest, quests]);

  useEffect(() => {
    function isPointsFormFieldInvalid() {
      if (points === undefined || points === 0 || Number.isNaN(points)) {
        return true;
      }
      return false;
    }

    setPointsFormFieldInvalid(isPointsFormFieldInvalid());
  }, [points]);

  useEffect(() => {
    if (selectedQuest) {
      setModalTitle('Edit quest');
      setTitle(selectedQuest.title);
      setPoints(selectedQuest.points);
      setGoal(selectedQuest.goal);
    } else {
      setModalTitle('Add quest');
      setTitle('');
      setPoints(undefined);
      setGoal(undefined);
    }
  }, [selectedQuest]);

  return (
    <>
      <Heading color="white-1" level="3" alignSelf="center" margin={{ bottom: 'small' }} data-testid="quest-form-title">
        {modalTitle}
      </Heading>
      <ModalDivider />
      <Box>
        <Box alignSelf="center" margin={{ top: 'small' }}>
          <Text color="nl-ash-0" margin={{ bottom: 'xsmall' }}>
            Title
          </Text>
          <Box alignSelf="center" round="small" background="white-1" width="medium">
            <TextInput
              plain
              maxLength={maxTitleLength}
              data-testid="edit-quest-name"
              aria-label="title"
              value={title || ''}
              onChange={(event) => setTitle(event.target.value)}
            />
          </Box>
          <ErrorMessage color="nl-ash-0">{titleErrorMessage}</ErrorMessage>
        </Box>
        <Box alignSelf="center">
          <Text color="nl-ash-0" margin={{ bottom: 'xsmall' }}>
            Points per quest
          </Text>
          <Box alignSelf="center" round="small" background="white-1" width="medium" margin={{ bottom: 'small' }}>
            <TextInput
              plain
              maxLength={pointsCharacterLimit}
              data-testid="edit-quest-points"
              aria-label="points"
              value={points || ''}
              onChange={(event) => setPoints(+event.target.value)}
              placeholder={'10'}
            />
          </Box>
        </Box>
        <Box alignSelf="center">
          <Text color="nl-ash-0" margin={{ bottom: 'small' }}>
            Total target number of completions
            <br />
          </Text>
          <Box alignSelf="center" round="small" background="white-1" width="medium">
            <TextInput
              plain
              maxLength={pointsCharacterLimit}
              aria-label="quest goal"
              data-testid="edit-quest-goal"
              value={goal || ''}
              onChange={(event) => {
                setGoal(+event.target.value);
              }}
              placeholder={'2'}
            />
          </Box>
        </Box>
      </Box>
      <div style={{ fontSize: '14px', color: '#fff', marginTop: '10px', display: 'flex' }}>
        <Info style={{ height: '16px', width: '16px', stroke: AppTheme.global.colors['nl-ash-0'] }} />
        Quest goal: points are multiplied by number of completions
      </div>
      <Box
        alignSelf="center"
        direction="row"
        align="center"
        justify="center"
        gap="small"
        width="xsmall"
        height="xxsmall"
        round="small"
        margin={{ top: 'medium' }}
      >
        <ModalButton margin={{ horizontal: 'small' }} content="Cancel" onClick={closeForm} />
        <ModalButton
          disabled={titleFormFieldInvalid || pointsFormFieldInvalid || isUpdatingQuest || isCreatingQuest}
          margin={{ horizontal: 'small' }}
          content={selectedQuest ? 'Save' : 'Create'}
          onClick={onSubmit}
        />
      </Box>
    </>
  );
}
