import { useQuery } from '@tanstack/react-query';
import { Challenge } from 'types';
import { getChallenge } from '../API';

export const challengeQueryKey = 'challenge';

export function useGetChallenge(challengeId: Challenge['id']) {
  return useQuery({
    queryKey: [challengeQueryKey, challengeId],
    queryFn: () => getChallenge(challengeId),
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
  });
}
