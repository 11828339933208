import { Box, Grid, Spinner, Text } from 'grommet';
import React, { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ChallengeCard } from './ChallengeCard';
import { AppContext } from 'context';
import { useGetAllChallenges } from 'hooks/useGetAllChallenges';
import styled from 'styled-components';

const LoadingBox = styled(Box)`
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`;

const NotFoundBox = styled(Box)`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IChallengeGridProps {
  responsive: any;
}

export const ChallengeGrid: React.FC<IChallengeGridProps> = ({ responsive }) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const appCtx = useContext(AppContext);
  const prevAppCtxValueRef = useRef(appCtx.challengeQuery);
  const favorites = appCtx.user?.favorites;
  const userId = appCtx.user?.id;

  const { data, fetchNextPage, hasNextPage, isLoading, isError, refetch } = useGetAllChallenges(
    appCtx.challengeQuery,
    userId,
  );

  const loadMoreChallenges = () => {
    if (!isLoading && hasNextPage && !isFetchingMore) {
      setIsFetchingMore(true);
      fetchNextPage();
    }
  };

  useEffect(() => {
    // Compare previous and current challengeName
    if (prevAppCtxValueRef.current !== appCtx.challengeQuery) {
      // Update the previous challenge name to current
      prevAppCtxValueRef.current = appCtx.challengeQuery;
      refetch();
    }
  }, [appCtx.challengeQuery, refetch]);

  // Reset fetching state after data is fetched
  useEffect(() => {
    if (!isLoading) {
      setIsFetchingMore(false);
    }
  }, [data, isLoading]);

  let favoriteIDs: Number[] = [];
  if (favorites !== undefined) favoriteIDs = favorites.map((obj) => obj.id);

  if (isError) return <div>Error fetching challenges!</div>;

  if ((data?.pages === undefined || favorites === undefined) && (isLoading || !userId)) {
    return (
      <InfiniteScroll loadMore={() => {}} hasMore={false}>
        <Grid
          justifyContent="stretch"
          align="start"
          gap={{ row: 'large', column: 'xlarge' }}
          margin={{
            left: '5vw',
            right: '5vw',
            top: '5vh',
            bottom: '5vh',
          }}
          columns={['auto', 'auto', 'auto', 'auto']}
        >
          {[...Array(8)].map((_, index) => (
            <ChallengeCard key={index} challenge={undefined} isFavorite={undefined} responsive={responsive} />
          ))}
        </Grid>
      </InfiniteScroll>
    );
  }

  if (data?.pages && data.pages[0].length === 0 && !isLoading) {
    return (
      <NotFoundBox>
        <Text size="large">No Challenges Found!</Text>
      </NotFoundBox>
    );
  }

  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={loadMoreChallenges}
      hasMore={hasNextPage}
      loader={
        <LoadingBox key={0}>
          <Spinner size="medium" />
        </LoadingBox>
      }
    >
      <Grid
        justifyContent="stretch"
        align="start"
        gap={{ row: 'large', column: 'xlarge' }}
        margin={{
          left: '5vw',
          right: '5vw',
          top: '5vh',
          bottom: '5vh',
        }}
        columns={responsive === 'small' ? ['auto'] : ['auto', 'auto', 'auto', 'auto']}
      >
        {data?.pages.map((page) =>
          page.map((challenge) => (
            <ChallengeCard
              key={challenge.id}
              challenge={challenge}
              isFavorite={favoriteIDs.includes(challenge.id)}
              responsive={responsive}
            />
          )),
        )}
      </Grid>
    </InfiniteScroll>
  );
};
