import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateChallenge } from '../API';
import { challengeQueryKey } from './useGetChallenge';

export function useUpdateChallenge() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateChallenge,
    onSuccess: () => {
      queryClient.invalidateQueries([challengeQueryKey]);
    },
  });
}
