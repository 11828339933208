import { Box, Image, Select, Text } from 'grommet';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { getAvatarImage } from '../avatars/Avatars';
import { Challenge, CompletedQuest } from 'types';
import AppTheme from 'themes/AppTheme';

const ALL = 'ALL TIME';
const PREVIOUS_WEEK = 'LAST WEEK';
const PREVIOUS_MONTH = 'LAST MONTH';

type FilterOption = typeof ALL | typeof PREVIOUS_WEEK | typeof PREVIOUS_MONTH;

interface TotalPointsTableProps {
  challenge: Challenge;
  questCompletions: CompletedQuest[];
}

const StyledTotalPointsTableInframe = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  overflow-y: visible;
`;

const StyledTotalPointsTableContainer = styled(Box)`
  border-radius: 25px;
  padding: 1em;
  background-color: ${AppTheme.global.colors['nl-lilac-100']};
  width: 100%;
  position: relative;
`;

const StyledTotalPointsTable = styled.table`
  display: block;
  table-layout: fixed;
  width: 100%;
`;

const StyledTotalPointsTableHeader = styled.thead`
  tr {
    display: flex;
    margin-left: 6em;
  }
  th {
    margin: 0 1px;
    box-sizing: border-box;
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td.empty-header {
    position: absolute;
    left: 0;
  }
`;

const StyledTotalPointsTableContent = styled.tbody`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-left: 6em;

  .player-id-container {
    display: flex;
    align-items: center;
    min-width: 170px;
    max-width: 170px;
    overflow: hidden;

    .player-avatar {
      max-height: 3em;
      width: 40%;
      display: flex;
    }

    .player-name {
      line-height: 1.5em;
      width: 60%;
    }
  }

  tr {
    display: flex;
    flex-direction: row;
    flex: 0 1 100%;
  }

  td {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5em;
    &:first-child {
      position: absolute;
      left: 0;
      background-color: ${AppTheme.global.colors['nl-lilac-100']};
    }
  }
`;

const StyledQuestTitleButton = styled.button`
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 1px;
  font-weight: 600;
  color: ${AppTheme.global.colors['nl-ash-900']};
  height: auto;
  text-wrap: wrap;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TotalPointsTable = ({ challenge, questCompletions }: TotalPointsTableProps) => {
  const [filter, setFilter] = useState<FilterOption>(ALL);
  const [filteredCompletions, setFilteredCompletions] = useState<CompletedQuest[]>([]);
  const [sortedByQuestId, setSortedByQuestId] = useState<number | undefined>(undefined);
  const [sortedPlayers, setSortedPlayers] = useState(challenge.players);
  const tdWidth = '150px';

  const filterCompletionsByTime = (newFilter: FilterOption) => {
    let filtered: CompletedQuest[] | null = null;
    switch (newFilter) {
      case ALL:
        break;
      case PREVIOUS_WEEK:
        filtered = questCompletions.filter(
          (completion) => moment(completion.completed).week() === moment().add(-1, 'week').week(),
        );
        break;
      case PREVIOUS_MONTH:
        filtered = questCompletions.filter(
          (completion) => moment(completion.completed).month() === moment().add(-1, 'months').month(),
        );
        break;
      default:
        break;
    }
    setFilteredCompletions(filtered || questCompletions);
  };

  const applyFilter = (newFilter: FilterOption) => {
    setFilter(newFilter);
    filterCompletionsByTime(newFilter);
  };

  const getPlayersSortedByTotalPoints = () => {
    const compareTotal = (playerA, playerB) => {
      const totalPointsA = playerA.points || 0;
      const totalPointsB = playerB.points || 0;

      if (totalPointsA < totalPointsB) {
        return 1;
      }
      if (totalPointsA > totalPointsB) {
        return -1;
      }
      return 0;
    };

    return [...sortedPlayers].sort(compareTotal);
  };

  const getPlayersSortedByQuestCompletions = () => {
    const completionsBySelectedQuest = filteredCompletions.filter((completion) => completion.quest === sortedByQuestId);

    const calculateCompletionTotalForPlayer = (player) => {
      let amountOfCompletions = 0;
      completionsBySelectedQuest.forEach((completion) => {
        if (completion.player === player.id) amountOfCompletions += 1;
      });
      return amountOfCompletions;
    };

    const completionsByPlayer = sortedPlayers.map((player) => {
      return { playerId: player.id, count: calculateCompletionTotalForPlayer(player) };
    });

    const compareQuestTotal = (playerA, playerB) => {
      const completionsByA = completionsByPlayer.find((completion) => completion.playerId === playerA.id)?.count || 0;
      const completionsByB = completionsByPlayer.find((completion) => completion.playerId === playerB.id)?.count || 0;

      if (completionsByA < completionsByB) {
        return 1;
      }
      if (completionsByA > completionsByB) {
        return -1;
      }
      return 0;
    };

    return [...sortedPlayers].sort(compareQuestTotal);
  };

  useEffect(() => {
    const sorted = sortedByQuestId ? getPlayersSortedByQuestCompletions() : getPlayersSortedByTotalPoints();
    setSortedPlayers(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedByQuestId]);

  useEffect(() => {
    filterCompletionsByTime(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questCompletions]);

  const questHeaders = challenge.quests.map((quest) => (
    <th key={quest.id}>
      <StyledQuestTitleButton
        onClick={() => setSortedByQuestId(quest.id)}
        style={{ minWidth: `${tdWidth}`, maxWidth: `${tdWidth}` }}
      >
        {quest.title}
      </StyledQuestTitleButton>
    </th>
  ));

  /* eslint-disable no-param-reassign */
  const questCompletionsByPlayer = sortedPlayers.reduce((map, player) => {
    // Map quests on same order as in header
    map[player.id] = challenge.quests.map((quest) => {
      const completions = filteredCompletions.filter(
        (completion) => completion.player === player.id && completion.quest === quest.id,
      );
      return completions;
    });
    return map;
  }, new Map<CompletedQuest, string[]>());
  /* eslint-enable no-param-reassign */

  const tableDataContent = sortedPlayers.map((player) => {
    return (
      <tr key={player.id} style={{ overflow: 'initial', marginTop: '1em' }}>
        <td className="player-id-container">
          <div className="player-avatar">
            <Image margin={{ bottom: 'xsmall' }} src={getAvatarImage(player.avatar)} fit="contain" />
          </div>
          <div className="player-name">{player.name}</div>
        </td>
        <td style={{ minWidth: `${tdWidth}`, maxWidth: `${tdWidth}` }}>
          <Text>{player.points}</Text>
        </td>
        {questCompletionsByPlayer[player.id].map((completions: CompletedQuest[], index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <td key={index} style={{ minWidth: `${tdWidth}`, maxWidth: `${tdWidth}` }}>
            <Text>{completions.length === 0 ? '-' : completions.length}</Text>
          </td>
        ))}
      </tr>
    );
  });

  return (
    <>
      <Box alignSelf="end" style={{ width: '200px', padding: '10px 0' }}>
        <Select
          size="small"
          options={[ALL, PREVIOUS_WEEK, PREVIOUS_MONTH]}
          value={filter}
          onChange={({ option }) => applyFilter(option)}
        />
      </Box>
      <StyledTotalPointsTableContainer data-testid="total-points-table">
        <StyledTotalPointsTableInframe>
          <StyledTotalPointsTable>
            <StyledTotalPointsTableHeader>
              {/* Disable ESLint rule for next line due to ESLint bug with th elements */
              /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <tr>
                <td className="empty-header" style={{ width: `${tdWidth}`, maxWidth: `${tdWidth}` }} />
                <th>
                  <StyledQuestTitleButton
                    onClick={() => setSortedByQuestId(undefined)}
                    style={{ minWidth: `${tdWidth}`, maxWidth: `${tdWidth}` }}
                  >
                    Total points
                  </StyledQuestTitleButton>
                </th>
                {questHeaders}
              </tr>
            </StyledTotalPointsTableHeader>
            <StyledTotalPointsTableContent>{tableDataContent}</StyledTotalPointsTableContent>
          </StyledTotalPointsTable>
        </StyledTotalPointsTableInframe>
      </StyledTotalPointsTableContainer>
    </>
  );
};

export default TotalPointsTable;
