import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import Confetti from 'react-dom-confetti';

import '../App.css';
import { ToastContext } from '../context';
import Quest from './Quest';
import { Challenge, CompletedQuest, Player, Quest as QuestType } from 'types';
import { useCreateQuestCompletion } from '../hooks/useCreateQuestCompletion';
import { useDeleteQuestCompletion } from '../hooks/useDeleteQuestCompletion';
import { useGetCompletedQuests } from '../hooks/useGetQuestCompletions';
import { useGetQuests } from '../hooks/useGetQuests';
import { useReorderQuests } from 'hooks/useReorderQuests';
import { Droppable } from 'components/feat/dnd/Droppable';
import { ReorderQuestsProps } from 'API';

const QuestContainer = styled(Box)`
  flex-flow: wrap;
  transition: 0.5s all linear;
  min-width: fit-content;
  margin: 1.5em 0;

  &.minimized {
    flex-flow: row;
  }
`;

interface QuestBarProps {
  challengeId: Challenge['id'];
  selectedPlayerId: Player['id'] | undefined;
  setNewPoints: (pointsSet: boolean) => void;
  setQuestBarMinimized: (arg0: boolean) => void;
  isMinimized: boolean;
}

export default function QuestBar({ selectedPlayerId, challengeId, setNewPoints, isMinimized }: QuestBarProps) {
  const toastCtx = useContext(ToastContext);
  const [hitGoal, setHitGoal] = useState(false);
  const { mutateAsync: createQuestCompletion, isLoading: isLoadingCreateQuestCompletion } = useCreateQuestCompletion();
  const { mutateAsync: deleteQuestCompletion, isLoading: isLoadingDeleteQuestCompletion } = useDeleteQuestCompletion();

  const { data: questCompletions = [] } = useGetCompletedQuests(challengeId);
  const { isSuccess: isSuccessQuests, data: quests } = useGetQuests(challengeId);

  const [localQuests, setLocalQuests] = useState<QuestType[]>();
  useEffect(() => {
    if (isSuccessQuests) {
      setLocalQuests(quests.filter((quest) => quest.active).sort((a, b) => (a.position || 0) - (b.position || 0)));
    }
  }, [isSuccessQuests, quests]);

  const { mutateAsync: reorderQuests } = useReorderQuests({ challengeId });

  const handleReordering = async (item: ReorderQuestsProps) => {
    await reorderQuests(item);
  };

  const completedQuestIds = useMemo<Array<CompletedQuest>>(() => {
    return questCompletions.filter((value) => value.player === selectedPlayerId);
  }, [questCompletions, selectedPlayerId]);

  const addPointsToQuest = (q: QuestType) => {
    if (q.goal !== undefined) {
      // Currently done quest completions plus the new one
      const amountOfCompletions = questCompletions.filter((value) => value.quest === q.id).length + 1;
      if (amountOfCompletions === q.goal) {
        setHitGoal(true);
        setTimeout(() => {
          setHitGoal(false); // Set immediately back to false
        }, 0);
      }
    }
    setNewPoints(true);
    setTimeout(() => setNewPoints(false), 100);
    if (selectedPlayerId) {
      createQuestCompletion({ quest: q, playerId: selectedPlayerId })
        .then(() => {})
        .catch((err) => toastCtx.setError(err));
    }
  };

  const filterQuestCompletions = (questId: QuestType['id']) => {
    return completedQuestIds.filter((value) => value.quest === questId);
  };

  const removePointsFromQuest = (q: QuestType) => {
    const lastCompletedQuest = filterQuestCompletions(q.id).pop();
    if (lastCompletedQuest && selectedPlayerId) {
      deleteQuestCompletion({ quest: q, questCompletionId: lastCompletedQuest.id, playerId: selectedPlayerId })
        .then(() => {})
        .catch((err) => toastCtx.setError(err));
    }
  };

  const hasMinimizedClass = isMinimized ? 'minimized' : '';

  return (
    <>
      {isSuccessQuests && (
        <>
          <Box style={!isMinimized ? { overflowX: 'auto' } : { overflowX: 'auto', width: '100%', paddingTop: '0.5em' }}>
            <QuestContainer className={hasMinimizedClass} justify="center">
              {localQuests && (
                <Droppable items={localQuests} setItems={setLocalQuests} updateOrder={handleReordering}>
                  {localQuests.map((quest) => (
                    <Quest
                      quest={quest}
                      completedQuests={filterQuestCompletions(quest.id)}
                      removePoints={() => removePointsFromQuest(quest)}
                      isLoading={isLoadingDeleteQuestCompletion || isLoadingCreateQuestCompletion}
                      addPoints={() => addPointsToQuest(quest)}
                      key={quest.id}
                    />
                  ))}
                </Droppable>
              )}
            </QuestContainer>
            <div style={{ textAlign: 'center' }}>
              {localQuests?.length === 0 ? (
                <span>Start your challenge by creating a quest in the Quests menu tab</span>
              ) : (
                !selectedPlayerId && <span>Select a character to enable the quests</span>
              )}
            </div>
          </Box>
        </>
      )}

      <Box width="100%" align="center">
        <Confetti active={hitGoal} />
      </Box>
    </>
  );
}
