import { Box, Grommet, Heading, Layer } from 'grommet';
import React, { KeyboardEvent, ReactNode } from 'react';

import { ModalTopIconHolder, ModalTheme } from './index';
import { Close } from 'grommet-icons';
import AppTheme from 'themes/AppTheme';
import { CloseButton } from 'themes/AppTheme';

export interface ModalProps {
  icon: ReactNode;
  heading: string;
  onClose: () => void;
  closeModal: () => void;
  children: ReactNode;
  dataTestId?: string;
}

export const Modal = ({ icon, heading, onClose, closeModal, children, dataTestId }: ModalProps) => {
  function handleClose(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      onClose();
    }
  }
  return (
    <Grommet theme={ModalTheme} data-testid={dataTestId}>
      <Layer style={{ background: 'none' }} responsive={false} onClickOutside={onClose} onKeyDown={handleClose}>
        <ModalTopIconHolder>{icon}</ModalTopIconHolder>
        <Box
          overflow="auto"
          round="medium"
          height={{ min: 'medium' }}
          width="large"
          background="nl-lilac-400"
          pad="large"
        >
          <CloseButton style={{ textAlign: 'right' }} onClick={closeModal}>
            <Close style={{ height: '35px', width: '35px', stroke: AppTheme.global.colors['nl-ash-0'] }} />
          </CloseButton>
          <Heading color="white-1" level="3" alignSelf="center" margin={{ bottom: 'small' }}>
            {heading}
          </Heading>
          {children}
        </Box>
      </Layer>
    </Grommet>
  );
};
