import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ChallengePage from 'challenge/ChallengePage';
import CreateChallenge from 'challenge/CreateChallenge';
import { AppBar } from 'components/appBar';
import { ErrorToast } from 'components/error';
import LandingPage from 'pages/LandingPage';
import { useInitUser } from 'hooks/useInitUser';

export default function AppRouter() {
  useInitUser();

  return (
    <Router>
      <AppBar />
      <Routes>
        <Route element={<CreateChallenge />} path="/new-challenge" />
        <Route element={<ChallengePage />} path="/challenge/:id" />
        <Route element={<LandingPage />} path="/" />
      </Routes>
      <ErrorToast />
    </Router>
  );
}
