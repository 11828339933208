import { Box, Footer, ResponsiveContext } from 'grommet';
import React, { useContext, useEffect } from 'react';
import { ReactComponent as HorseRider } from 'img/horse-rider.svg';
import AppTheme from 'themes/AppTheme';
import { AppDispatchContext } from '../context';
import { ChallengeGrid } from 'components/challengesView';

export default function LandingPage() {
  const dispatchCtx = useContext(AppDispatchContext);

  useEffect(() => {
    dispatchCtx.clearTitle();
    document.title = 'ChallengeApp';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <Box direction="column" overflow="visible" height="100vh">
          <ChallengeGrid responsive={responsive} />
          <Box style={{ paddingBottom: '30px' }}></Box>

          <Footer justify="end" background="nl-lilac-600" style={{ position: 'fixed', bottom: 0 }} width="100vw">
            <HorseRider fill={AppTheme.global.colors['nl-ash-0']} height={'35px'} />
            <HorseRider fill={AppTheme.global.colors['nl-ash-0']} height={'35px'} />
            <HorseRider fill={AppTheme.global.colors['nl-ash-0']} height={'35px'} />
          </Footer>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}
