import { Box, Button, Grommet, Heading, Text } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Close, Optimize } from 'grommet-icons';
import { Challenge, CompletedQuest, Quest } from 'types';
import { ProgressBar } from 'components/progressBar';
import { ToastContext } from '../context';
import TotalPointsTable from '../quests/TotalPointsTable';
import { ModalTopIconHolder, ModalTheme } from 'components/modal';
import { useGetCompletedQuests } from 'hooks/useGetQuestCompletions';
import AppTheme, { CloseButton } from 'themes/AppTheme';

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
`;

const StyledTotalPointsContainer = styled(Box)`
  margin-bottom: 1.5em;
  h1 {
    margin-top: 0;
  }
`;

const StyledQuestTotalsContainer = styled(Box)`
  flex-flow: wrap;
  place-content: space-between;
  margin-bottom: 1em;
`;

const StyledIndividualQuest = styled(Box)`
  width: 45%;
  margin-bottom: 1em;
`;

const StyledInactiveQuestsToggleButton = styled(Button)`
  background-color: ${AppTheme.global.colors['nl-ash-200']};
  margin-bottom: 1em;
`;

const StyledQuestTitleSpan = styled(Text)`
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface TotalPointsModalProps {
  closeTotalPointsModal(close: boolean): void;
  challengeData: Challenge;
}

const calculateTotalPercentage = (progressTotal: number, challengeData: Challenge) => {
  const progressPercentage = progressTotal > 0 ? (progressTotal / challengeData.goal) * 100 : 0;
  return Math.min(progressPercentage, 100);
};

export function questProgress(quest: Quest, completions: CompletedQuest[]) {
  return completions.filter((c) => c.quest === quest.id).length;
}

const constructIndividualQuests = (quests: Quest[], completions: CompletedQuest[]) => {
  return quests.map((quest) => {
    // Ignore quests without goals
    if (!quest.goal) return null;

    // Number of quests completed
    const questProgression = questProgress(quest, completions);
    const questProgressPercentage = Math.min((questProgression * 100) / quest.goal, 100);

    // eslint-disable-next-line consistent-return
    return (
      <StyledIndividualQuest key={quest.id} data-testid="individual-quest-container">
        <StyledQuestTitleSpan color="white-1">
          {quest.title} ({questProgression}/{quest.goal})
        </StyledQuestTitleSpan>
        <ProgressBar
          progression={questProgressPercentage}
          bgcolor={AppTheme.global.colors['nl-lilac-600']}
          hasCompleted={questProgression >= quest.goal}
        />
      </StyledIndividualQuest>
    );
  });
};

const TotalPointsModal = ({ closeTotalPointsModal, challengeData }: TotalPointsModalProps) => {
  const [progression, setProgression] = useState(0);
  const [showDisabledQuests, setShowDisabledQuests] = useState(false);
  const toastCtx = useContext(ToastContext);

  const {
    isError: isCompletedQuestsError,
    error: completedQuestsError,
    data: questCompletions = [],
  } = useGetCompletedQuests(challengeData.id);

  const progressTotal = challengeData.players.map((player) => player.points).reduce((a, b) => a + b, 0);
  const progressPercentage = calculateTotalPercentage(progressTotal, challengeData);
  const hasGoal = challengeData.goal !== null && challengeData.goal !== 0;
  const activeQuests = challengeData.quests && challengeData.quests.filter((quest) => quest.active && quest.goal);
  const inactiveQuests = challengeData.quests && challengeData.quests.filter((quest) => !quest.active && quest.goal);

  const individualQuestsContent =
    activeQuests && activeQuests.length > 0 && constructIndividualQuests(activeQuests, questCompletions);
  const inactiveQuestsContent =
    inactiveQuests && inactiveQuests.length > 0 && constructIndividualQuests(inactiveQuests, questCompletions);

  useEffect(() => {
    setInterval(() => setProgression(progressPercentage), 400);
  }, [progressPercentage]);

  useEffect(() => {
    if (isCompletedQuestsError) {
      toastCtx.setError(completedQuestsError as Error);
    }
  }, [toastCtx, isCompletedQuestsError, completedQuestsError]);

  return (
    <Grommet theme={ModalTheme}>
      <ModalOverlay onClick={() => closeTotalPointsModal(true)}>
        <ModalTopIconHolder style={{ marginTop: '1em' }}>
          <Optimize size="large" />
        </ModalTopIconHolder>
        <Box
          style={{
            margin: '0 auto',
            cursor: 'default',
            marginBottom: '3em',
            borderRadius: '24px',
            minHeight: 'fit-content',
          }}
          width="large"
          background="nl-lilac-400"
          pad="large"
          onClick={(e) => e.stopPropagation()}
        >
          <StyledTotalPointsContainer data-testid="totals-modal">
            <CloseButton
              style={{ textAlign: 'right' }}
              onClick={() => closeTotalPointsModal(true)}
              data-testid="total-points-close-btn"
            >
              <Close style={{ height: '35px', width: '35px', stroke: AppTheme.global.colors['nl-ash-0'] }} />
            </CloseButton>
            <Heading level="2" color="white-1">
              Total {`${progressTotal} ${hasGoal ? `/ ${challengeData.goal}` : ''}`}
            </Heading>
            {hasGoal && (
              <ProgressBar progression={progression} bgcolor={AppTheme.global.colors['nl-lilac-600']} height="30px" />
            )}
          </StyledTotalPointsContainer>
          {individualQuestsContent && (
            <>
              <StyledQuestTotalsContainer>{individualQuestsContent}</StyledQuestTotalsContainer>
              {inactiveQuestsContent && (
                <>
                  <StyledInactiveQuestsToggleButton
                    data-testid="toggle-inactive-quests"
                    label={
                      <Text size="normal" weight="bold">
                        {!showDisabledQuests
                          ? `Show ${inactiveQuestsContent.length} disabled quest(s)`
                          : 'Hide disabled quests'}
                      </Text>
                    }
                    onClick={() => setShowDisabledQuests(!showDisabledQuests)}
                  />
                  {showDisabledQuests && (
                    <StyledQuestTotalsContainer>{inactiveQuestsContent}</StyledQuestTotalsContainer>
                  )}
                </>
              )}
            </>
          )}
          {challengeData.quests.length > 0 ? (
            <TotalPointsTable challenge={challengeData} questCompletions={questCompletions} />
          ) : (
            <Heading level="3" color="white-1" data-testid="no-quests-heading">
              You haven&apos;t created any quests yet!
            </Heading>
          )}
        </Box>
      </ModalOverlay>
    </Grommet>
  );
};

export default TotalPointsModal;
