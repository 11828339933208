import * as Storage from 'utils/local-storage-utils';
import { Player } from 'types';

export function addToHistory(player: Player) {
  const history = Storage.get<Player[]>(Storage.SELECTED_PLAYER);

  if (history) {
    const index = history.findIndex((item) => item?.challengeId === player?.challengeId);

    if (index !== -1) {
      history.splice(index, 1);
      history.push(player);
      Storage.save(Storage.SELECTED_PLAYER, history);
    } else {
      history.push(player);
      Storage.save(Storage.SELECTED_PLAYER, history);
    }
  } else {
    Storage.save(Storage.SELECTED_PLAYER, [player] as Player[]);
  }
}

export function getFromHistory(challengeId: Player['challengeId']) {
  const history = Storage.get<Player[]>(Storage.SELECTED_PLAYER);

  if (history) {
    const index = history.findIndex((item) => item?.challengeId === challengeId);

    if (index !== -1) {
      return history[index];
    }

    return;
  }
}

export function clearHistory() {
  Storage.clear(Storage.HISTORY_ARRAY);
}
