import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateQuestProps, createQuest } from '../API';
import { Quest } from 'types';
import { questsQueryKey } from './useGetQuests';
import { challengeQueryKey } from './useGetChallenge';

export function useCreateQuest(onError) {
  const queryClient = useQueryClient();

  return useMutation<Quest, Error, CreateQuestProps>({
    mutationFn: createQuest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [questsQueryKey], refetchType: 'all' });
      queryClient.invalidateQueries([challengeQueryKey]);
    },
    onError,
  });
}
