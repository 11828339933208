export function daysUntil(date: Date): string {
  const dayInMillis = 1000 * 60 * 60 * 24;
  const today = new Date();
  const daysLeft = Math.ceil((date.getTime() - today.getTime()) / dayInMillis);
  if (daysLeft === 0) {
    return 'This is the last day of the challenge!';
  }
  if (daysLeft >= 0) {
    return `${daysLeft} day${daysLeft === 1 ? '' : 's'} left`;
  }
  return `Challenge ended ${Math.abs(daysLeft)} day${daysLeft === -1 ? '' : 's'} ago`;
}
