import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteCompletionProps, deleteQuestCompletion } from '../API';
import { Challenge, CompletedQuest, DeleteCompletion } from 'types';
import { challengeQueryKey } from './useGetChallenge';
import { completedQuestsQueryKey } from './useGetQuestCompletions';
import { updatePointsForPlayerInArray } from './util';

export function useDeleteQuestCompletion() {
  const queryClient = useQueryClient();

  return useMutation<DeleteCompletion, Error, DeleteCompletionProps, () => void>({
    mutationFn: deleteQuestCompletion,
    onMutate: async (deletedCompletion: DeleteCompletionProps) => {
      await queryClient.cancelQueries([completedQuestsQueryKey]);
      await queryClient.cancelQueries([challengeQueryKey]);

      const snapshotCompletions = queryClient.getQueryData<Array<CompletedQuest>>([completedQuestsQueryKey], {
        exact: false,
      });
      const snapshotChallenge = queryClient.getQueryData<Challenge>([challengeQueryKey], { exact: false });

      if (snapshotCompletions && snapshotChallenge) {
        let updatedCompletions = [...snapshotCompletions];
        updatedCompletions = updatedCompletions.filter(
          (completion) => completion.id !== deletedCompletion.questCompletionId,
        );

        queryClient.setQueryData<Array<CompletedQuest>>(
          [completedQuestsQueryKey, snapshotChallenge.id],
          updatedCompletions,
        );
        queryClient.setQueryData<Challenge>([challengeQueryKey, snapshotChallenge.id], {
          ...snapshotChallenge,
          players: updatePointsForPlayerInArray(
            snapshotChallenge.players,
            deletedCompletion.playerId,
            -deletedCompletion.quest.points,
          ),
        });
        return () => {
          queryClient.setQueryData<Array<CompletedQuest>>(
            [challengeQueryKey, snapshotChallenge.id],
            snapshotCompletions,
          );
          queryClient.setQueryData<Challenge>([challengeQueryKey, snapshotChallenge.id], snapshotChallenge);
        };
      }
    },
    onError: (error, variables, rollback) => {
      if (rollback) {
        rollback();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([challengeQueryKey]);
      queryClient.invalidateQueries({ queryKey: [completedQuestsQueryKey], exact: false, refetchType: 'all' });
    },
  });
}
