import img from 'img/card-images/horse.png';
import { Text, Box, Image, Spinner, Tip } from 'grommet';
import React from 'react';
import styled, { css } from 'styled-components';
import { Star, User, Group } from 'grommet-icons';
import AppTheme from 'themes/AppTheme';
import { Challenge } from 'types';
import { Link } from 'react-router-dom';

const linkStyle = {
  width: '17vw',
  height: '30vh',
  minHeight: '200px',
  textDecoration: 'none',
  color: 'inherit',
};

const linkStyleSmall = {
  width: '40vw',
  height: '60vh',
  minHeight: '200px',
  textDecoration: 'none',
  color: 'inherit',
};

const HoverableBox = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: 5%;
  box-shadow: 4px 8px 8px hsla(0, 0%, 0%, 0.38);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ImageBox = styled.div`
  ${() => css`
    width: 100%;
    height: 45%;
  `}
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 10px;
`;

interface IChallengeCardProps {
  challenge: Challenge | undefined;
  isFavorite: boolean | undefined;
  responsive: any;
}

function getDate(date: Date): any {
  const dateObj = new Date(date);

  const dayRaw = dateObj.getDate();
  const monthRaw = dateObj.getMonth() + 1; // Adding 1 because months are zero-based
  const year = dateObj.getFullYear() % 100; // Getting only the last two digits of the year

  // Padding single-digit day and month with leading zero if needed
  const day = dayRaw < 10 ? `0${dayRaw}` : dayRaw;
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw;
  return { day, month, year };
}

function toDatesString(date1: Date, date2: Date): String {
  const { day: dayStart, month: monthStart, year: yearStart } = getDate(date1);
  const { day: dayEnd, month: monthEnd, year: yearEnd } = getDate(date2);

  return `${dayStart}.${monthStart}.${yearStart} - ${dayEnd}.${monthEnd}.${yearEnd}`;
}

export const ChallengeCard: React.FC<IChallengeCardProps> = ({
  challenge,
  isFavorite,
  responsive,
}: IChallengeCardProps) => {
  const isTeamChallenge = false; // TODO: this should come from challenge when teams implemented

  if (challenge === undefined || isFavorite === undefined) {
    return (
      <Link
        onClick={(event) => event.preventDefault()}
        to={''}
        style={responsive === 'small' ? linkStyleSmall : linkStyle}
      >
        <HoverableBox direction="column">
          <ImageBox />
          <Box align="center" />
          <Box align="center">
            <Spinner />
          </Box>
          <Box flex="grow" />
          <Box direction="row"></Box>
        </HoverableBox>
      </Link>
    );
  }

  return (
    <Link to={`/challenge/${challenge.id}`} style={responsive === 'small' ? linkStyleSmall : linkStyle}>
      <HoverableBox direction="column">
        <ImageBox>
          <Image src={img} fill={true} width="50vw" style={{ borderRadius: '5% 5% 0 0' }} />
        </ImageBox>
        <Box align="center" style={{ display: 'inline-grid' }}>
          <Tip content={challenge.title}>
            <StyledText textAlign="center" size="large" weight="bold">
              {challenge.title}
            </StyledText>
          </Tip>
        </Box>
        <Box align="center" style={{ display: 'inline-grid' }}>
          <Tip content={challenge.description}>
            <StyledText textAlign="center">{challenge.description}</StyledText>
          </Tip>
        </Box>

        <Box flex="grow" />

        <Box direction="row">
          {isTeamChallenge ? (
            <Group style={{ paddingLeft: '5px' }} />
          ) : (
            <User style={{ paddingLeft: '5px', paddingBottom: '5px' }} />
          )}
          <Box flex="grow" />
          {toDatesString(challenge.start, challenge.end)}
          <Box flex="grow" />
          {isFavorite && (
            <Star
              style={{
                height: '20px',
                width: '20px',
                fill: AppTheme.global.colors['nl-lilac-600'],
                paddingRight: '5px',
              }}
            />
          )}
        </Box>
      </HoverableBox>
    </Link>
  );
};
