import React from 'react';
import { Text } from 'grommet';

export interface SubHeaderProps {
  content: any;
}

export function SubHeader({ content }: SubHeaderProps) {
  return (
    <Text color="white" alignSelf="center" truncate size="small" weight="bold" margin="none" data-testid="sub-header">
      {content}
    </Text>
  );
}
