import { AppDispatchContext } from 'context';
import { Box, TextInput } from 'grommet';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export function ChallengeSearch() {
  const [text, setText] = useState('');
  const dispatchCtx = useContext(AppDispatchContext);

  // Use useCallback to memoize the debouncedSetChallengeQuery function to avoid re-creating on renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetChallengeQuery = useCallback(
    debounce((value) => {
      dispatchCtx.setChallengeQuery(value);
    }, 1000),
    [dispatchCtx],
  );

  useEffect(() => {
    return () => {
      debouncedSetChallengeQuery.cancel();
    };
  }, [debouncedSetChallengeQuery]);

  const onTextModified = (value: string) => {
    setText(value);
    debouncedSetChallengeQuery(value);
  };

  return (
    <Box
      width={'100%'}
      overflow={'hidden'}
      style={{
        borderRadius: '4px',
        border: '1px solid white',
      }}
    >
      <TextInput
        placeholder="Type challenge name..."
        value={text}
        onChange={(event) => onTextModified(event.target.value)}
        style={{
          color: 'black',
          backgroundColor: 'white',
        }}
      />
    </Box>
  );
}
