import { Image, ImageExtendedProps } from 'grommet';
import * as React from 'react';
import { Avatar, getAvatarImage } from '../../avatars/Avatars';

interface AvatarImageProps extends ImageExtendedProps {
  children: Avatar;
  cursor?: string | undefined;
  dataTestId?: string;
}

export const AvatarImage = ({
  children,
  onClick,
  fit,
  height = '100px',
  width,
  cursor = 'auto',
  dataTestId,
}: AvatarImageProps) => {
  return (
    <Image
      fit={fit}
      onClick={onClick}
      style={{ userSelect: 'none', height, width, cursor }}
      data-testid={dataTestId}
      src={getAvatarImage(children)}
    />
  );
};
