import { Box, Image, Text, Tip } from 'grommet';
import * as React from 'react';

import { getAvatarImage } from '../avatars/Avatars';
import { Challenge, Player } from 'types';
import { PlayerAnimation } from './ChallengeAnimations';
import { addToHistory } from 'utils/LocalHistory';
import styled from 'styled-components';
import AppTheme from 'themes/AppTheme';

const StyledText = styled(Text)`
  color: white !important;
  text-shadow: 2px 2px 2px ${AppTheme.global.colors['nl-lilac-900']};
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const selectedPlayerAnimation = (newPoints: boolean, setShowAvatarModal: (show: boolean) => void, user: Player) =>
  newPoints ? (
    <Box
      animation={{
        type: 'jiggle',
        delay: 0,
        duration: 1000,
        size: 'medium',
      }}
    >
      <Image
        style={{ minWidth: '50px', cursor: 'pointer', userSelect: 'none' }}
        onClick={() => setShowAvatarModal(true)}
        margin={{ bottom: 'xsmall' }}
        src={getAvatarImage(user.avatar)}
        fit="contain"
      />
    </Box>
  ) : (
    <PlayerAnimation>
      <Image
        data-testid={`${user.name}-${user.avatar}-avatar`}
        style={{ minWidth: '50px', cursor: 'pointer', userSelect: 'none' }}
        onClick={() => setShowAvatarModal(true)}
        margin={{ bottom: 'xsmall' }}
        src={getAvatarImage(user.avatar)}
        fit="contain"
      />
    </PlayerAnimation>
  );

const ChallengeLaneBorder = () => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    height="5px"
    background="nl-ash-0"
    elevation="medium"
  />
);

function playerPos(user, players, goal) {
  if (!goal) {
    const minPoints = Math.min(...players.map((p) => p.points));
    const maxPoints = Math.max(...players.map((p) => p.points));
    const myPoints = user.points;
    const ratio = (myPoints - minPoints) / (maxPoints - minPoints);
    return `${100 * ratio}%`;
  }
  const myPoints = user.points;
  const ratio = myPoints / goal;
  return `${100 * ratio}%`;
}

interface ChallengeLaneProps {
  index: number;
  user: Player;
  isSelected: boolean;
  selectPlayer: (playerId: Player['id']) => void;
  players: Player[];
  setShowAvatarModal: (show: boolean) => void;
  goal: Challenge['goal'] | undefined;
  newPoints: boolean;
}

const ChallengeLane = React.forwardRef<HTMLDivElement, ChallengeLaneProps>(
  (
    { index, user, isSelected, selectPlayer, players, setShowAvatarModal, goal, newPoints }: ChallengeLaneProps,
    ref,
  ) => {
    return (
      <div ref={ref}>
        {index === 0 && <ChallengeLaneBorder />}
        <Box
          fill="horizontal"
          tag="header"
          direction="row"
          align="center"
          justify="between"
          height="50px"
          data-testid={isSelected ? 'challenge-player-lane-selected' : 'challenge-player-lane'}
          background={isSelected ? 'nl-lilac-500' : 'nl-lilac-400'}
          elevation="medium"
          focusIndicator={false}
          onClick={() => {
            addToHistory(user);
            selectPlayer(user.id);
          }}
        >
          <Box
            width="200px"
            justify="center"
            align="center"
            fill="vertical"
            overflow="hidden"
            border={{ size: '5px', color: 'nl-ash-0', side: 'right' }}
          >
            <Box width="90%" color="nl-ash-0">
              <Tip content={user.name}>
                <StyledText style={{ marginBottom: '5px', marginTop: '5px' }}>{user.name}</StyledText>
              </Tip>
            </Box>
            <Box pad={{ horizontal: 'small' }} color="nl-ash-0">
              <StyledText truncate weight="bold" size="large">
                {user.points}p
              </StyledText>
            </Box>
          </Box>
          <Box
            direction="row"
            align="start"
            fill
            border={{ size: '5px', color: 'nl-ash-0', side: 'right' }}
            margin={{ right: 'small' }}
          >
            <Box width={playerPos(user, players, goal)} style={{ maxWidth: 'calc(100% - 50px)' }} />
            <Box align="start" style={{ maxWidth: '50px' }} fill="vertical">
              {isSelected ? (
                selectedPlayerAnimation(newPoints, setShowAvatarModal, user)
              ) : (
                <Box>
                  <Image
                    style={{ minWidth: '50px', userSelect: 'none' }}
                    margin={{ bottom: 'xsmall' }}
                    src={getAvatarImage(user.avatar)}
                    fit="contain"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <ChallengeLaneBorder />
      </div>
    );
  },
);

export default ChallengeLane;
