import { deepMerge } from 'grommet/utils';

import AppTheme from 'themes/AppTheme';

export const ButtonTheme = deepMerge(AppTheme, {
  global: {
    font: {
      weight: 'bold',
    },
    colors: {
      'white-1': 'nl-ash-0',
    },
    active: {
      background: {
        opacity: 1,
        color: 'nl-ash-500',
      },
    },
  },
  button: {
    extend: 'text-transform: uppercase; box-shadow: 0 4px 2px -2px nl-ash-300;',
    color: { dark: 'white-1', light: 'nl-ash-900' },
    primary: {
      color: 'nl-ash-0',
      width: '20px',
    },
  },
});
