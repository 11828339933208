import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateQuestProps, updateQuest } from '../API';
import { questsQueryKey } from './useGetQuests';
import { challengeQueryKey } from './useGetChallenge';

export function useUpdateQuest(onError?) {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UpdateQuestProps>({
    mutationFn: updateQuest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [questsQueryKey], refetchType: 'all' });
      queryClient.invalidateQueries([challengeQueryKey]);
    },
    onError,
  });
}
