import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RemoveQuestProps, removeQuest } from '../API';
import { questsQueryKey } from './useGetQuests';
import { challengeQueryKey } from './useGetChallenge';

export function useRemoveQuest(onError?) {
  const queryClient = useQueryClient();

  return useMutation<void, Error, RemoveQuestProps>({
    mutationFn: removeQuest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [questsQueryKey], refetchType: 'all' });
      queryClient.invalidateQueries([challengeQueryKey]);
    },
    onError,
  });
}
