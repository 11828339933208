import '../App.css';

import { Box, Button, ResponsiveContext, Text } from 'grommet';
import { LineChart, SettingsOption, Target, User } from 'grommet-icons';
import React, { CSSProperties, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useScrollPosition from '@react-hook/window-scroll';

import { RunningManLoader } from 'components/loader';
import { useGetChallenge } from 'hooks/useGetChallenge';
import boidFlock from 'img/boid-flock-pastels.svg';
import AvatarModal from 'modals/AvatarModal';
import ChallengeModal from 'modals/ChallengeModal';
import TotalPointsModal from 'modals/TotalPointsModal';
import UserModal from 'modals/UserModal';
import QuestBar from 'quests/QuestBar';
import QuestModal from 'quests/QuestModal';
import AppTheme from 'themes/AppTheme';
import { Player } from 'types';
import { getFromHistory } from 'utils/LocalHistory';
import { AppContext, AppDispatchContext, ChallengeContext, ToastContext } from '../context';
import ChallengeLane from './ChallengeLane';

const ChallengePageBox = styled(Box)`
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: column;
  display: flex;
  position: relative;
  z-index: 1;
  background-color: ${AppTheme.global.colors['nl-lilac-100']};
  background-image: url(${boidFlock});
`;

const LaneHeaderButton = styled(Button)`
  border-radius: 0;
  background-color: ${AppTheme.global.colors['nl-lilac-500']};
  border: 5px solid ${AppTheme.global.colors['nl-ash-0']};
  text-shadow: 2px 2px 2px ${AppTheme.global.colors['nl-lilac-900']};
  border-bottom: none;
  display: flex;
  padding: 0.3em 0.9em 0.3em 0.9em;
  min-height: 100%;

  .icon-container {
    margin-top: -2px;
    align-self: center;
    svg {
      -webkit-filter: drop-shadow(2px 2px 2px ${AppTheme.global.colors['nl-lilac-900']});
      filter: drop-shadow(2px 2px 2px ${AppTheme.global.colors['nl-lilac-900']});
    }
  }
  @media only screen and (max-width: 400px) {
    span {
      display: none;
    }
  }
`;

const StyledText = styled(Text)`
  color: white !important;
  text-shadow: 2px 2px 2px ${AppTheme.global.colors['nl-lilac-900']};
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-weight: bold !important;
  text-align: center;
  size: large;
`;

export default function ChallengePage() {
  const { id } = useParams();

  // TODO: Fix checking bad id values in AppRouter
  const getIdRouteParameter = (): number => {
    return parseInt(id ? id : '');
  };

  const appCtx = useContext(AppContext);
  const dispatchCtx = useContext(AppDispatchContext);
  const toastCtx = useContext(ToastContext);
  const challengeCtx = useContext(ChallengeContext);

  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showQuestModal, setShowQuestModal] = useState(false);
  const [showChallengeModal, setShowChallengeModal] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState<Player['id'] | undefined>(undefined);
  const [showTotalPointsModal, setShowTotalPointsModal] = useState(false);
  const [newPoints, setNewPoints] = useState(false);
  const [isQuestBarMinimized, setQuestBarMinimized] = useState(true);

  const challengeId = getIdRouteParameter();

  const {
    isLoading: isChallengeLoading,
    isError: isChallengeError,
    error: challengeError,
    data: challenge,
  } = useGetChallenge(challengeId);

  useEffect(() => {
    return () => {
      // On unmount clear the challenge.
      challengeCtx.clearChallenge();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let totalPoints = 0;
  if (challenge?.players) {
    totalPoints = challenge.players.reduce((points, player) => points + player.points, 0) || 0;
  }

  useEffect(() => {
    if (!isChallengeLoading && !isChallengeError && appCtx.title !== challenge.title) {
      dispatchCtx.setTitle(challenge.title);
      document.title = `${challenge.title} | ChallengeApp`;
    }
  }, [appCtx, dispatchCtx, isChallengeLoading, challenge?.title, isChallengeError]);

  useEffect(() => {
    if (challenge && challengeCtx.challenge !== challenge) {
      challengeCtx.setChallenge(challenge);
    }
    if (challenge?.players.length === 0) {
      setShowUserModal(true);
    }
    if (challenge?.id) {
      setSelectedPlayerId(getFromHistory(challenge.id)?.id);
    }
  }, [challengeCtx, challenge]);

  useEffect(() => {
    if (isChallengeError && toastCtx.error !== challengeError) {
      toastCtx.setError(challengeError as Error);
    }
  }, [toastCtx, isChallengeError, challengeError]);

  // Get the last rendered player lane for its offset and height
  const lastLaneRef = React.useRef<HTMLDivElement>(null);

  function useCleanupRefs(value) {
    const isMountedRef = useRef<boolean | null>(null);
    const ref = useRef(0);
    useEffect(() => {
      isMountedRef.current = true;
      if (isMountedRef.current) {
        ref.current = value;
      }
      return function cleanup() {
        isMountedRef.current = false;
      };
    });
    return ref.current;
  }

  const scrollPosition = useScrollPosition(60);
  const oldScrollPosition = useCleanupRefs(scrollPosition);
  // Define the scroll offset treshold when to trigger the bar mini/maximizing event
  const scrollTriggerOffset = useCleanupRefs(
    lastLaneRef.current ? lastLaneRef.current.offsetTop + lastLaneRef.current.offsetHeight : 0,
  );

  useLayoutEffect(() => {
    const scrollAmount = window.innerHeight + scrollPosition;
    if (scrollAmount >= scrollTriggerOffset && isQuestBarMinimized) {
      if (oldScrollPosition < scrollPosition) {
        setQuestBarMinimized(false);
      }
    } else if (oldScrollPosition > scrollPosition) {
      setQuestBarMinimized(true);
    }
  }, [scrollPosition, isQuestBarMinimized, oldScrollPosition, scrollTriggerOffset]);

  function renderLanes(players: Player[]) {
    return players.map((player, index) => (
      <ChallengeLane
        key={player.name}
        isSelected={selectedPlayerId === player.id}
        selectPlayer={setSelectedPlayerId}
        user={player}
        players={players}
        index={index}
        setShowAvatarModal={setShowAvatarModal}
        goal={challenge?.goal}
        newPoints={newPoints}
        ref={index === players.length - 1 ? lastLaneRef : undefined}
      />
    ));
  }

  function openUserModal() {
    setShowUserModal(true);
  }

  function closeUserModal() {
    setShowUserModal(false);
  }

  function openQuestModal() {
    setShowQuestModal(true);
  }

  function closeQuestModal() {
    setShowQuestModal(false);
  }

  function selectPlayerFromUserModal(player: Player) {
    if (selectedPlayerId !== player.id) {
      setSelectedPlayerId(player.id);
    }
    closeUserModal();
  }

  if (!challenge) {
    const err = challengeError as Error;

    var errorMessage = `Challenge not found ...`;
    if (err?.message === 'Request timed out') {
      errorMessage = `Request timed out. Please, try refreshing the page after 2 minutes. If issue persists, contact ChallengeApp developers.`;
    }
    return <RunningManLoader isError={isChallengeError} errorText={errorMessage} />;
  }

  const challengeContentPlayers =
    challenge.players && challenge.players.length > 0 ? (
      renderLanes(challenge.players)
    ) : (
      <Box
        align="center"
        height="100px"
        pad={{ vertical: 'small' }}
        fill="horizontal"
        background="brand"
        border={{ color: 'white', size: '10px', side: 'horizontal' }}
      />
    );

  const questBarMinimizedStyle: CSSProperties = {
    width: '100%',
    backgroundColor: 'brand',
    bottom: 0,
    alignItems: 'center',
  };

  const questBarNormalStyle: CSSProperties = {
    padding: '0.5em',
  };

  const questBarDisabled: CSSProperties = {
    pointerEvents: 'none',
    opacity: 0.5,
  };

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <ChallengePageBox height={{ min: '100%' }} responsive id="challengePage">
          <Box overflow={{ horizontal: 'auto', vertical: 'hidden' }}>
            <Box direction="row" justify="center" style={{ minWidth: 'fit-content' }}>
              <Box direction="row" justify="center" gap="small">
                <Box align="start" height="48px">
                  <LaneHeaderButton
                    type="submit"
                    size="medium"
                    a11yTitle="Open user management"
                    label={
                      <>
                        {responsive !== 'small' ? (
                          <Text size="large" weight="bold" color={'nl-ash-0'} margin={{ right: '0.2em' }}>
                            Players
                          </Text>
                        ) : null}
                        <Box className="icon-container">
                          <User color="white" />
                        </Box>
                      </>
                    }
                    onClick={() => {
                      openUserModal();
                    }}
                  />
                </Box>

                <Box align="start" height="48px">
                  <LaneHeaderButton
                    type="submit"
                    size="medium"
                    a11yTitle="Open quest management"
                    data-testid="quests-button"
                    label={
                      <>
                        {responsive !== 'small' ? (
                          <Text size="large" weight="bold" color={'nl-ash-0'} margin={{ right: '0.2em' }}>
                            Quests
                          </Text>
                        ) : null}
                        <Box className="icon-container">
                          <Target color="white" />
                        </Box>
                      </>
                    }
                    onClick={() => {
                      openQuestModal();
                    }}
                  />
                </Box>

                <Box
                  height="xxsmall"
                  width="30%"
                  pad={{ horizontal: 'small' }}
                  alignSelf="center"
                  background="brand"
                  style={{ borderTop: 'solid 5px white', minWidth: 'fit-content' }}
                  justify="center"
                  align="center"
                  border={{ size: '5px', color: 'white', side: 'vertical' }}
                  direction="row"
                >
                  <Box align="center" width="small" height="30px" margin={{ horizontal: 'xxsmall' }}>
                    <StyledText size="large" style={{ paddingRight: '3px' }} truncate>
                      Total: {totalPoints}
                    </StyledText>
                  </Box>
                  <Box align="center" width="small" height="30px" margin={{ horizontal: 'xxsmall' }}>
                    <StyledText size="large" style={{ paddingRight: '3px' }} truncate>
                      Goal: {challenge.goal}
                    </StyledText>
                  </Box>
                </Box>

                <Box align="start" height="48px">
                  <LaneHeaderButton
                    type="submit"
                    size="small"
                    a11yTitle="Edit challenge"
                    label={
                      <>
                        {responsive !== 'small' ? (
                          <Text
                            size="large"
                            wordBreak="break-all"
                            weight="bold"
                            color={'nl-ash-0'}
                            margin={{ right: '0.2em' }}
                          >
                            Challenge
                          </Text>
                        ) : null}
                        <Box className="icon-container">
                          <SettingsOption color="white" />
                        </Box>
                      </>
                    }
                    onClick={() => {
                      setShowChallengeModal(true);
                    }}
                  />
                </Box>

                <Box align="center" height="48px">
                  <LaneHeaderButton
                    type="submit"
                    size="medium"
                    a11yTitle="Open overview"
                    label={
                      <>
                        {responsive !== 'small' ? (
                          <Text size="large" weight="bold" color={'nl-ash-0'} margin={{ right: '0.2em' }}>
                            Overview
                          </Text>
                        ) : null}
                        <Box className="icon-container">
                          <LineChart color="white" />
                        </Box>
                      </>
                    }
                    onClick={() => {
                      setShowTotalPointsModal(true);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {challengeContentPlayers}
          {showChallengeModal && (
            <ChallengeModal
              challenge={challenge}
              closeChallengeModal={() => {
                setShowChallengeModal(false);
              }}
            />
          )}
          {showUserModal && (
            <UserModal challenge={challenge} selectPlayer={selectPlayerFromUserModal} closeModal={closeUserModal} />
          )}
          {showQuestModal && <QuestModal closeQuestModal={closeQuestModal} challengeId={challenge.id} />}
          {selectedPlayerId ? (
            <>
              <Box style={isQuestBarMinimized ? questBarMinimizedStyle : questBarNormalStyle}>
                <QuestBar
                  challengeId={challenge.id}
                  selectedPlayerId={selectedPlayerId}
                  setNewPoints={setNewPoints}
                  setQuestBarMinimized={setQuestBarMinimized}
                  isMinimized={isQuestBarMinimized}
                />
              </Box>
              {showAvatarModal && (
                <AvatarModal
                  close={() => setShowAvatarModal(false)}
                  selectedPlayer={challenge.players.find((p) => p.id === selectedPlayerId) || challenge.players[0]}
                />
              )}
            </>
          ) : (
            <Box
              style={
                isQuestBarMinimized
                  ? { ...questBarMinimizedStyle, ...questBarDisabled }
                  : { ...questBarNormalStyle, ...questBarDisabled }
              }
            >
              <QuestBar
                challengeId={challenge.id}
                selectedPlayerId={selectedPlayerId}
                setNewPoints={setNewPoints}
                setQuestBarMinimized={setQuestBarMinimized}
                isMinimized={isQuestBarMinimized}
              />
            </Box>
          )}
          {showTotalPointsModal && (
            <TotalPointsModal closeTotalPointsModal={() => setShowTotalPointsModal(false)} challengeData={challenge} />
          )}
        </ChallengePageBox>
      )}
    </ResponsiveContext.Consumer>
  );
}
