import React from 'react';
import { Button, Box, Text, Tip } from 'grommet';
import { Add, Subtract } from 'grommet-icons';
import styled from 'styled-components';

import AppTheme from 'themes/AppTheme';
import { CompletedQuest, Quest as QuestType } from 'types';
import { Divider } from 'components/common/divider';
import { Draggable } from 'components/feat/dnd/Draggable';

const QuestBox = styled.div`
  display: flex;
  height: 80px;
  width: 180px;
  margin: 0.6em;
`;

const StyledText = styled(Text)`
  line-height: 14px;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const color = AppTheme.global.colors['nl-lilac-400'];
const borderColor = AppTheme.global.colors['nl-lilac-500'];

const CenterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin: 0 0.2rem;
  background: ${color};
  border: 0.2em solid ${borderColor};
  padding: 4px;
`;

const LeftButton = styled(Button)`
  border-radius: 10px 0px 0px 10px;
  padding: 0 0.1em;
  background: ${color};
  border: 0.2em solid ${borderColor};
  box-shadow: 0 4px 2px -2px gray;
`;

const RightButton = styled(Button)`
  border-radius: 0px 10px 10px 0px;
  padding: 0 0.1em;
  background: ${color};
  border: 0.2em solid ${borderColor};
  box-shadow: 0 4px 2px -2px gray;
`;

interface QuestProps {
  quest: QuestType;
  completedQuests: CompletedQuest[];
  isLoading: boolean;
  removePoints(): void;
  addPoints(): void;
}

export default function Quest({ quest, completedQuests, isLoading, removePoints, addPoints }: QuestProps) {
  return (
    <Draggable id={quest.id}>
      <QuestBox>
        <LeftButton
          data-no-dnd="true"
          icon={<Subtract />}
          disabled={isLoading || !completedQuests.length}
          onClick={removePoints}
        />
        <CenterBox>
          <Box justify="center" align="center">
            <Box height="40px" overflow="hidden" justify="center" alignContent="center">
              <Tip content={`${quest.title} (${quest.points}p)`}>
                <StyledText>
                  {quest.title} ({quest.points}p)
                </StyledText>
              </Tip>
            </Box>
            <Divider />
            <Box style={{ height: '40px', padding: '.5em', width: '100%', fontSize: '16px' }}>
              {completedQuests.length * quest.points}p
            </Box>
          </Box>
        </CenterBox>

        <RightButton data-no-dnd="true" icon={<Add />} disabled={isLoading} onClick={addPoints} />
      </QuestBox>
    </Draggable>
  );
}
