import React, { MouseEvent } from 'react';

import {
  MouseSensor as LibMouseSensor,
  useSensor,
  useSensors,
  DndContext,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';

import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';

interface ItemWithId {
  id: number | string;
  [key: string]: any;
}

interface DroppableProps {
  items: ItemWithId[];
  children: React.ReactNode;
  setItems: (item: any) => any;
  updateOrder: (item: any) => any;
}

export const Droppable = ({ items, children, setItems, updateOrder }: DroppableProps) => {
  // Prevent drag activation for elements with data-no-dnd="true"
  // https://github.com/clauderic/dnd-kit/issues/477#issuecomment-1713536492
  const handler = ({ nativeEvent: event }: MouseEvent) => {
    let cur = event.target as HTMLElement;

    while (cur) {
      if (cur.dataset && cur.dataset.noDnd) {
        return false;
      }
      cur = cur.parentElement as HTMLElement;
    }

    return true;
  };

  class MouseSensor extends LibMouseSensor {
    static activators = [{ eventName: 'onMouseDown', handler }] as (typeof LibMouseSensor)['activators'];
  }

  const sensors = useSensors(useSensor(MouseSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      // eslint-disable-next-line
      setItems((items: any) => {
        const oldIndex = items.findIndex(({ id }) => id === active.id);
        const newIndex = items.findIndex(({ id }) => id === over.id);

        updateOrder({ id: active.id, newPosition: newIndex });

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};
