import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePlayer } from '../API';
import { playersQueryKey } from './useGetPlayers';
import { challengeQueryKey } from './useGetChallenge';

export function useDeletePlayer(onError?) {
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>({
    mutationFn: deletePlayer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [playersQueryKey], refetchType: 'all' });
      queryClient.invalidateQueries([challengeQueryKey]);
    },
    onError,
  });
}
