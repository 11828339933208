import { Box } from 'grommet';
import { headShake, rubberBand, tada, zoomOutRight } from 'react-animations';
import styled, { keyframes } from 'styled-components';

export const ShakeAnimation = keyframes`${headShake}`;

export const TadaAnimation = keyframes`${tada}`;

export const PulseAnimation = keyframes`${rubberBand}`;

export const LoadingAnimation = styled(Box)`
  animation: 2s ${TadaAnimation} infinite;
`;

export const FetchFailAnimation = styled(Box)`
  animation: 1s ${ShakeAnimation};
`;

export const customBounce = keyframes`
  0% { transform: translate3d(0, -40%, 0) }
  100% { transform: translate3d(0, 0, 0) }
`;
export const PlayerAnimation = styled(Box)`
  animation: ${customBounce} 0.5s linear alternate infinite;
`;

export const ZoomOutRightAnimation = keyframes`${zoomOutRight}`;

export const RunningAnimation = styled(Box)`
  animation: 1s ${ZoomOutRightAnimation} infinite;
`;
