import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface DraggableProps {
  id: number | string;
  children: React.ReactNode;
}

export const Draggable = ({ id, children }: DraggableProps) => {
  const { setNodeRef, listeners, attributes, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};
