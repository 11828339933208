import { Box, Grommet, Heading, Layer } from 'grommet';
import { Elevator } from 'grommet-icons';
import React, { useContext, useState } from 'react';

import { ToastContext } from '../context';
import { Player } from 'types';
import { ModalTopIconHolder, ModalDivider, ModalButton, ModalButtonContainer, ModalTheme } from '../components/modal';
import { AvatarSelector } from '../components/avatar';
import { useUpdatePlayer } from '../hooks/useUpdatePlayer';

interface AvatarModalProps {
  selectedPlayer: Player;
  close: () => void;
}

export default function AvatarModal({ selectedPlayer, close }: AvatarModalProps) {
  const [avatar, setAvatar] = useState(selectedPlayer.avatar);
  const toastCtx = useContext(ToastContext);

  const { isLoading, mutateAsync: updatePlayer } = useUpdatePlayer((err: Error) => toastCtx.setError(err));

  async function handleAvatarSelection() {
    await updatePlayer({ playerId: selectedPlayer.id, player: { ...selectedPlayer, avatar: avatar } });
    close();
  }

  return (
    <Grommet theme={ModalTheme} data-testid="avatar-modal">
      <Layer style={{ background: 'none' }} responsive={false} onClickOutside={close}>
        <ModalTopIconHolder>
          <Elevator size="large" />
        </ModalTopIconHolder>
        <Box round="medium" style={{ minHeight: 'fit-content' }} width="large" background="nl-lilac-400" pad="large">
          <Heading level="3" color="nl-ash-0" alignSelf="center" margin={{ bottom: 'small' }}>
            Change Avatar
          </Heading>
          <ModalDivider />
          <AvatarSelector currentAvatar={selectedPlayer.avatar} chooseAvatar={setAvatar}></AvatarSelector>
          <ModalButtonContainer>
            <>
              <ModalButton margin={{ horizontal: 'small' }} content="Cancel" disabled={isLoading} onClick={close} />
              <ModalButton
                margin={{ horizontal: 'small' }}
                content={isLoading ? 'Saving...' : 'Save'}
                disabled={isLoading}
                onClick={handleAvatarSelection}
              />
            </>
          </ModalButtonContainer>
        </Box>
      </Layer>
    </Grommet>
  );
}
