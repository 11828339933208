import { useInfiniteQuery } from '@tanstack/react-query';
import { getAllChallenges } from '../API';

const pageSize = 8;

export function useGetAllChallenges(query: string, userId: string | undefined) {
  return useInfiniteQuery(
    // Request will be re-sent if the TanStack query ID changes (query or userId in this case)
    ['allChallenges' + query.toString() + userId],
    ({ pageParam = 1 }) =>
      getAllChallenges({
        page: pageParam,
        pageSize,
        query,
        userId: userId,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < pageSize) {
          return null;
        }
        return allPages.length + 1;
      },
    },
  );
}
