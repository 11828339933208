import { Box, Text } from 'grommet';
import { User, Edit, UserAdd } from 'grommet-icons';
import React, { useContext, useState } from 'react';

import { ToastContext } from '../context';
import { Challenge, Player, PlayerData } from 'types';
import { Modal, ModalButton, ModalButtonContainer, ModalDivider } from 'components/modal';
import { Dialog } from 'components/common/dialog';
import AddEditUserModal from './AddEditUserModal';
import { AvatarImage } from 'components/avatar';
import { useCreatePlayer } from 'hooks/useCreatePlayer';
import { useDeletePlayer } from 'hooks/useDeletePlayer';
import { useUpdatePlayer } from 'hooks/useUpdatePlayer';
import { useGetPlayers } from 'hooks/useGetPlayers';
import { HorseRiderSpinner } from 'components/loader';
import { LoadingAnimation } from 'challenge/ChallengeAnimations';
import AppTheme from 'themes/AppTheme';

interface UserModalProps {
  challenge: Challenge;
  selectPlayer(p: Player): void;
  closeModal(): void;
}

enum ViewState {
  UsersView,
  AddUserView,
  EditUserView,
  UserIsBeingAddedView,
  UserIsBeingEditedView,
}

export default function UserModal({ challenge, selectPlayer, closeModal }: UserModalProps) {
  const [view, setView] = useState<ViewState>(ViewState.UsersView);
  const [selectedPlayer, setSelectedPlayer] = useState<Player>();
  const [newPlayer, setNewPlayer] = useState<PlayerData>();
  const [isConfirmDeleteDialogVisible, setIsConfirmDeleteDialogVisible] = useState(false);
  const toastCtx = useContext(ToastContext);

  const { mutateAsync: createPlayer } = useCreatePlayer((err) => toastCtx.setError(err));
  const { mutateAsync: updatePlayer } = useUpdatePlayer((err) => toastCtx.setError(err));
  const { mutateAsync: deletePlayer } = useDeletePlayer((err) => toastCtx.setError(err));
  const { data: players = [], isLoading } = useGetPlayers(challenge.id);

  const addNewPlayer = async (name: Player['name'], avatar: Player['avatar']) => {
    const player: PlayerData = { name: name, avatar: avatar };
    setNewPlayer(player);

    setView(ViewState.UserIsBeingAddedView);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1s to allow the user to see the loading panel
    await createPlayer({ challengeId: challenge.id, player: player });
    setView(ViewState.UsersView);
  };

  const editPlayer = async (name: Player['name'], avatar: Player['avatar']) => {
    if (selectedPlayer === undefined) {
      toastCtx.setError(Error('No player defined on edit!'));
      return;
    }
    const editedPlayer = { ...selectedPlayer, avatar: avatar, name: name };
    setSelectedPlayer(editedPlayer);

    setView(ViewState.UserIsBeingEditedView);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // wait for 1s to allow the user to see the loading panel
    await updatePlayer({ playerId: selectedPlayer.id, player: editedPlayer });
    setView(ViewState.UsersView);
  };

  const askDelete = () => {
    setIsConfirmDeleteDialogVisible(true);
  };

  const onDelete = async (id: Player['id']) => {
    await deletePlayer(id);
    setView(ViewState.UsersView);
    setIsConfirmDeleteDialogVisible(false);
  };
  function renderContent(state: ViewState) {
    switch (state) {
      case ViewState.UsersView: {
        return (
          <Modal
            heading="Manage players"
            onClose={() => {
              if (selectedPlayer) {
                selectPlayer(selectedPlayer);
              }
            }}
            closeModal={() => {
              closeModal();
            }}
            icon={<User size="large" />}
            dataTestId="users-view"
          >
            <ModalDivider />
            {!isLoading && players?.length !== 0 && (
              <Text style={{ textAlign: 'center' }} color={'nl-ash-0'}>
                Add, delete or edit players
              </Text>
            )}
            <Box overflow="auto" wrap direction="row" flex justify="evenly" style={{ minHeight: '330px' }}>
              {isLoading && (
                <Box align="center" justify="center">
                  <HorseRiderSpinner
                    textColor="nl-ash-0"
                    iconColor={AppTheme.global.colors['nl-ash-0']}
                    loadingText=""
                  />
                </Box>
              )}
              {!isLoading &&
                players?.length > 0 &&
                players.map((player) => {
                  return (
                    <Box
                      key={player.id}
                      width="130px"
                      margin={{ horizontal: '5px', top: '10px', bottom: '95px' }}
                      pad={{ vertical: '5px' }}
                      height="55px"
                      round="small"
                      align="center"
                      direction="column"
                    >
                      <AvatarImage height="80px">{player.avatar}</AvatarImage>
                      <Text key={player.name}>{player.name}</Text>
                      <ModalButton
                        a11yTitle="Edit selected player"
                        icon={<Edit />}
                        content="Edit"
                        margin={{ top: 'xsmall' }}
                        onClick={() => {
                          setSelectedPlayer(player);
                          setView(ViewState.EditUserView);
                        }}
                      />
                    </Box>
                  );
                })}
              {!isLoading && players?.length === 0 && (
                <Box align="center" justify="center">
                  <Text color="nl-ash-0">
                    It&apos;s awfully empty in here... <br />
                    Click below to get started!
                  </Text>
                </Box>
              )}
            </Box>
            <ModalDivider />
            <ModalButtonContainer>
              <ModalButton
                content={
                  <Box direction="row" align="center" height="100%" justify="center">
                    <UserAdd />
                    <Text size="small" margin={{ left: '10px' }} style={{ lineHeight: '15px', userSelect: 'none' }}>
                      Add new
                    </Text>
                  </Box>
                }
                onClick={() => {
                  setSelectedPlayer(undefined);
                  setView(ViewState.AddUserView);
                }}
              />
            </ModalButtonContainer>
          </Modal>
        );
      }
      case ViewState.AddUserView: {
        return (
          <Modal
            heading="Add player"
            onClose={() => setView(ViewState.UsersView)}
            closeModal={() => {
              closeModal();
            }}
            icon={<User size="large" />}
            dataTestId="add-user-view"
          >
            <ModalDivider />
            <Text style={{ textAlign: 'center' }} color={'nl-ash-0'}>
              Select a name and character
            </Text>
            <AddEditUserModal
              challengeId={challenge.id}
              selectedPlayer={selectedPlayer}
              onSubmit={(n: Player['name'], a: Player['avatar']) => addNewPlayer(n, a)}
              onCancel={() => setView(ViewState.UsersView)}
            ></AddEditUserModal>
          </Modal>
        );
      }
      case ViewState.UserIsBeingAddedView: {
        return (
          <Modal
            heading="Add player"
            onClose={() => setView(ViewState.UsersView)}
            closeModal={() => {
              closeModal();
            }}
            icon={<User size="large" />}
            dataTestId="user-being-added-view"
          >
            <ModalDivider />
            <Text style={{ textAlign: 'center', margin: '20px 0' }} color={'nl-ash-0'}>
              Player {newPlayer?.name} is being onboarded...
            </Text>
            <Box overflow="auto" wrap direction="row" flex justify="evenly" style={{ minHeight: '150px' }}>
              {newPlayer && (
                <LoadingAnimation>
                  <AvatarImage fit="contain" height="100px" cursor="pointer">
                    {newPlayer.avatar}
                  </AvatarImage>
                </LoadingAnimation>
              )}
            </Box>
          </Modal>
        );
      }
      case ViewState.EditUserView: {
        return (
          <Modal
            heading="Edit player"
            onClose={() => setView(ViewState.UsersView)}
            closeModal={() => {
              closeModal();
            }}
            icon={<User size="large" />}
            dataTestId="edit-user-view"
          >
            <ModalDivider />
            <AddEditUserModal
              challengeId={challenge.id}
              selectedPlayer={selectedPlayer}
              onSubmit={(n: Player['name'], a: Player['avatar']) => editPlayer(n, a)}
              onCancel={() => setView(ViewState.UsersView)}
              onDelete={askDelete}
            ></AddEditUserModal>

            {isConfirmDeleteDialogVisible && selectedPlayer && (
              <Dialog
                text={`Delete player '${selectedPlayer.name}'.`}
                onConfirm={async () => onDelete(selectedPlayer.id)}
                onCancel={() => {
                  setIsConfirmDeleteDialogVisible(false);
                }}
              />
            )}
          </Modal>
        );
      }
      case ViewState.UserIsBeingEditedView: {
        return (
          <Modal
            heading="Edit player"
            onClose={() => setView(ViewState.UsersView)}
            closeModal={() => {
              closeModal();
            }}
            icon={<User size="large" />}
            dataTestId="user-being-edited-view"
          >
            <ModalDivider />
            <Text style={{ textAlign: 'center', margin: '20px 0' }} color={'nl-ash-0'}>
              {selectedPlayer?.name} is getting some upgrades...
            </Text>
            <Box overflow="auto" wrap direction="row" flex justify="evenly" style={{ minHeight: '150px' }}>
              {selectedPlayer && (
                <LoadingAnimation>
                  <AvatarImage fit="contain" height="100px" cursor="pointer">
                    {selectedPlayer.avatar}
                  </AvatarImage>
                </LoadingAnimation>
              )}
            </Box>
          </Modal>
        );
      }
    }
  }

  return renderContent(view);
}
