export const USER_KEY = 'user';
export const UNAUTHORIZED_PATH = 'unauthorized_path';
export const HISTORY_ARRAY = 'history';
export const SELECTED_PLAYER = 'selected-players';

type StorageKey = typeof USER_KEY | typeof UNAUTHORIZED_PATH | typeof HISTORY_ARRAY | typeof SELECTED_PLAYER;

/**
 * Save item to localstorage
 * @param key
 * @param item
 */
export const save = <T>(key: StorageKey, item: T) => {
  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Get item from local storage
 *
 * @param key
 */
export const get = <T>(key: StorageKey): T | undefined => {
  const item = localStorage.getItem(key);
  return item ? (JSON.parse(item) as T) : undefined;
};

/**
 * Clear item from local storage
 *
 * @param key
 */
export const clear = (key: StorageKey) => {
  localStorage.removeItem(key);
};
