import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createChallenge } from '../API';
import { Challenge, ChallengeData } from 'types';
import { challengeQueryKey } from './useGetChallenge';

export function useCreateChallenge(onError?) {
  const queryClient = useQueryClient();

  return useMutation<Challenge, Error, ChallengeData>({
    mutationFn: createChallenge,
    onSuccess: () => {
      queryClient.invalidateQueries([challengeQueryKey]);
    },
    onError,
  });
}
