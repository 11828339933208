import { Button } from 'grommet';
import { Star, StarOutline } from 'grommet-icons';
import { useFavorites } from 'hooks/useFavorites';
import React from 'react';
import styled from 'styled-components';
import AppTheme from 'themes/AppTheme';
import { Challenge } from 'types';

const ToggleFavoriteButton = styled(Button)`
  box-shadow: 0 4px 2px -2px ${AppTheme.global.colors['nl-ash-700']};
  font-size: 22px;
  padding: 5px 8px;
  background: ${AppTheme.global.colors['nl-lilac-100']};
  color: black;
  border: ${AppTheme.global.colors['nl-lilac-300']} solid 4px;

  &:hover {
    box-shadow: ${AppTheme.global.colors['nl-lilac-100']} 0px 0px 0px 2px;
  }
`;

interface IFavoriteButtonProps {
  challengeId: Challenge['id'];
  isFavorited: boolean;
}

export const FavoriteButton: React.FC<IFavoriteButtonProps> = ({ challengeId, isFavorited }: IFavoriteButtonProps) => {
  const { addFavoriteToUser, removeFavoriteFromUser, isLoading } = useFavorites();

  async function handleToggleFavorite() {
    if (isFavorited) {
      await removeFavoriteFromUser(challengeId);
    } else {
      await addFavoriteToUser(challengeId);
    }
  }

  return (
    <ToggleFavoriteButton
      aria-label={isFavorited ? 'Remove challenge from favourites' : 'Add challenge to favourites'}
      primary
      onClick={handleToggleFavorite}
      disabled={isLoading}
    >
      {isFavorited ? (
        <Star style={{ height: '35px', width: '35px', fill: AppTheme.global.colors['nl-lilac-600'] }} />
      ) : (
        <StarOutline style={{ height: '35px', width: '35px', stroke: AppTheme.global.colors['nl-lilac-600'] }} />
      )}
    </ToggleFavoriteButton>
  );
};
