import React from 'react';
import { Text, Box } from 'grommet';

import { RunningAnimation } from 'challenge/ChallengeAnimations';
import { AvatarImage } from 'components/avatar';
import { randomAvatar } from 'avatars/Avatars';

export interface AnimalLoaderProps {
  text: string;
  animation?: 'loading' | 'running';
  dataTestid?: string;
}

export const AnimalLoader = ({ text, dataTestid }: AnimalLoaderProps) => {
  return (
    <>
      <Text style={{ textAlign: 'center', margin: '20px 0' }} color={'nl-ash-0'} data-testid={dataTestid}>
        {text}
      </Text>
      <Box overflow="auto" wrap direction="row" flex justify="evenly" style={{ minHeight: '150px' }}>
        {Array.from({ length: 3 }, (_, index) => (
          <RunningAnimation key={index}>
            <AvatarImage fit="contain" height="100px" cursor="pointer">
              {randomAvatar()}
            </AvatarImage>
          </RunningAnimation>
        ))}
      </Box>
    </>
  );
};
