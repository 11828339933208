import React, { KeyboardEvent } from 'react';
import { Layer, Card, CardHeader, CardBody, CardFooter } from 'grommet';

import { Divider } from 'components/common/divider';
import { CAButton } from 'components/common/button';

export interface DialogProps {
  header?: string;
  text: string;
  confirmText?: string;
  cancelText?: string;
  a11yTitle?: string;
  disabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const Dialog = ({
  header = 'Confirmation',
  text,
  confirmText = 'OK',
  cancelText = 'Cancel',
  disabled,
  onConfirm,
  onCancel,
}: DialogProps) => {
  const handleClose = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <Layer responsive={false} onClickOutside={onCancel} onKeyDown={handleClose} style={{ borderRadius: '24px' }}>
      <Card style={{ fontWeight: 'bold' }} background="brand" width="medium">
        <CardHeader color="nl-ash-0" pad="small">
          {header}
        </CardHeader>
        <Divider />
        <CardBody color="nl-ash-0" pad="medium">
          {text}
        </CardBody>
        <CardFooter style={{ justifyContent: 'center' }} pad="small">
          <CAButton primary disabled={disabled} label={cancelText} onClick={onCancel} />
          <CAButton primary disabled={disabled} label={confirmText} onClick={onConfirm} />
        </CardFooter>
      </Card>
    </Layer>
  );
};
