import { Box, Button, Layer, Text } from 'grommet';
import { FormClose, StatusCritical } from 'grommet-icons';
import React, { useContext } from 'react';

import { ToastContext } from 'context';

export function ErrorToast() {
  const toastCtx = useContext(ToastContext);
  const onClose = () => toastCtx.clearToast();

  if (!toastCtx.error) {
    return <></>;
  }
  return (
    <Layer
      position="bottom-right"
      modal={false}
      margin={{ vertical: 'medium', horizontal: 'small' }}
      onClick={onClose}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad={{ vertical: 'small', horizontal: 'medium' }}
        background="status-critical"
      >
        <Box align="center" direction="row" gap="xsmall">
          <StatusCritical />
          <Text>{`Error: ${toastCtx.error.message}`}</Text>
        </Box>
        <Button icon={<FormClose />} onClick={onClose} plain />
      </Box>
    </Layer>
  );
}
