import { getUser } from '../API';
import { Player, User } from 'types';

export function updatePointsForPlayerInArray(
  players: Player[],
  playerId: Player['id'],
  pointsChange: number,
): Player[] {
  return players.map((p) =>
    p.id === playerId
      ? {
          ...p,
          points: p.points + pointsChange,
        }
      : p,
  );
}

export async function updateUser(userId: User['id'], setUser: (user: User) => void) {
  const user = await getUser(userId);
  setUser(user);
}
