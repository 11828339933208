import boid from 'img/animals/boid.png';
import bull from 'img/animals/bull.png';
import bunny from 'img/animals/bunny.png';
import cat from 'img/animals/cat.png';
import chicken from 'img/animals/chicken.png';
import chicken_2 from 'img/animals/chicken_2.png'; // eslint-disable-line
import dog from 'img/animals/dog.png';
import fox from 'img/animals/fox.png';
import frog from 'img/animals/frog.png';
import goat from 'img/animals/goat.png';
import koala from 'img/animals/koala.png';
import monkey from 'img/animals/monkey.png';
import mouse from 'img/animals/mouse.png';
import piggy from 'img/animals/piggy.png';
import pug from 'img/animals/pug.png';
import reindeer from 'img/animals/reindeer.png';

export const avatars = {
  boid,
  bull,
  bunny,
  cat,
  chicken,
  chicken_2,
  dog,
  fox,
  frog,
  goat,
  koala,
  monkey,
  mouse,
  piggy,
  pug,
  reindeer,
};

export type Avatar = keyof typeof avatars;

export function getAvatarImage(id: Avatar) {
  return avatars[id] || monkey;
}

export function randomAvatar(): Avatar {
  const rand = Math.floor(Math.random() * Object.keys(avatars).length);
  const avatar = Object.keys(avatars)[rand] as Avatar;

  return avatar;
}
