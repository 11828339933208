import { Box, BoxProps } from 'grommet';

import React, { ReactNode } from 'react';
export interface ModalButtonContainerProps extends BoxProps {
  children: ReactNode;
}

export const ModalButtonContainer = ({ children }: ModalButtonContainerProps) => {
  return (
    <Box
      margin={{ top: 'medium' }}
      alignSelf="center"
      direction="row"
      align="center"
      justify="center"
      gap="small"
      width="xsmall"
      height="xxsmall"
      round="small"
    >
      {children}
    </Box>
  );
};
