import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Challenge, Quest } from 'types';
import { reorderQuests, ReorderQuestsProps } from '../API';
import { questsQueryKey } from './useGetQuests';

interface Props {
  challengeId: Challenge['id'];
}

export function useReorderQuests({ challengeId }: Props) {
  const queryClient = useQueryClient();

  return useMutation<Quest[], Error, ReorderQuestsProps>({
    mutationFn: reorderQuests,
    onSuccess: async (updatedQuests) => {
      queryClient.setQueryData([questsQueryKey, challengeId], updatedQuests);
    },
  });
}
