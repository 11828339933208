import React, { createContext, useReducer } from 'react';

/**
 * Valid actions for toast context
 */
const SET_ERROR_TOAST = 'SET_ERROR_TOAST';
const CLEAR_TOASTS = 'CLEAR_TOASTS';

/**
 * Toast state
 *
 * @interface ToastState
 */
interface ToastState {
  error?: Error;
  setError: (error: Error) => void;
  clearToast: () => void;
}

/**
 * Toast context
 */
export const ToastContext = createContext({} as ToastState);

/**
 * Reducer for toast state
 *
 * @param {ToastState} state
 * @param {({ type: string; payload: Error | undefined })} action
 * @returns
 */
const toastReducer = (state: ToastState, action: { type: string; payload: Error | undefined }) => {
  switch (action.type) {
    case SET_ERROR_TOAST:
      return {
        ...state,
        error: action.payload,
      } as ToastState;
    case CLEAR_TOASTS:
      return {
        ...state,
        error: undefined,
      } as ToastState;
    default:
      return state;
  }
};

/**
 * Toast context provider
 *
 * @param {{ [x: string]: any }} { ...props }
 * @returns
 */
export const ToastContextProvider = ({ ...props }: { [x: string]: any }) => {
  const [state, dispatch] = useReducer(toastReducer, {} as ToastState);
  const { children } = props;
  return (
    <ToastContext.Provider
      value={{
        ...state,
        setError: (err: Error) => dispatch({ type: SET_ERROR_TOAST, payload: err }),
        clearToast: () => dispatch({ type: CLEAR_TOASTS, payload: undefined }),
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
