import { Button } from 'grommet';
import styled from 'styled-components';

// Currently contains Netlight colors
const AppTheme = {
  global: {
    colors: {
      brand: '#6559FF', // lilac-500; needs to be under brand variable to overwrite grommet default color
      'nl-lilac-100': '#efedff',
      'nl-lilac-200': '#d6d8ff',
      'nl-lilac-300': '#b0a8ff',
      'nl-lilac-400': '#9d8dff',
      'nl-lilac-500': '#6559ff',
      'nl-lilac-600': '#4a49cb',
      'nl-lilac-900': '#2c2668',

      'nl-ash-0': '#ffffff',
      'nl-ash-50': '#fafafa',
      'nl-ash-100': '#f5f5f5',
      'nl-ash-200': '#eeeeee',
      'nl-ash-300': '#e0e0e0',
      'nl-ash-400': '#bdbdbd',
      'nl-ash-500': '#9e9e9e',
      'nl-ash-600': '#757575',
      'nl-ash-700': '#616161',
      'nl-ash-800': '#424242',
      'nl-ash-900': '#212121',
      'nl-ash-1000': '#000000',

      'nl-sunshine-100': '#fdf5e6',
      'nl-sunshine-200': '#fed9a8',
      'nl-sunshine-300': '#f6ca84',
      'nl-sunshine-400': '#fdbf6d',
      'nl-sunshine-500': '#ffb04b',
      'nl-sunshine-600': '#d67900',
      'nl-sunshine-700': '#af6500',
      'nl-sunshine-800': '#885200',
      'nl-sunshine-900': '#603f00',

      focus: '#6559FF', // brand
      text: { dark: '#fafafa', light: '#212121' },
    },
    focus: {
      border: {
        color: 'primary',
      },
    },
    font: {
      family: 'Nunito',
      size: '18px',
      height: '20px',
    },
    checkBox: {
      border: {
        color: {
          dark: 'white-1',
        },
      },
      check: {
        color: {
          dark: 'white-1',
          light: 'white-1',
        },
      },
    },
    formField: {
      border: {
        size: 'large',
      },
    },
  },
  select: {
    background: '#f8f8f8',
    options: {
      text: {
        size: 'xsmall',
        margin: '0',
      },
    },
  },
  table: {
    header: {
      align: 'start',
      pad: { horizontal: 'xsmall', vertical: 'xsmall' },
      border: 'bottom',
      extend: 'display: none', // Hides header from ChallengeSearch
    },
  },
  tip: {
    content: {
      background: '#ffffff',
    },
  },
};

export const CloseButton = styled(Button)`
  display: flex;
  align-self: flex-end;
  cursor: pointer;

  &:hover {
    background: ${AppTheme.global.colors['nl-lilac-300']};
    transform: scale(1.1);
    border-radius: 5px;
  }
`;

export default AppTheme;
