import { Tools } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalButton, ModalButtonContainer, ModalDivider } from 'components/modal';
import { Dialog } from 'components/common/dialog';
import { Challenge } from 'types';
import { ToastContext } from '../context';
import React, { useContext, useState } from 'react';
import { Box, TextInput, Text, DateInput } from 'grommet';
import { ErrorMessage } from 'components/error';
import moment from 'moment';
import { useUpdateChallenge } from 'hooks/useUpdateChallenge';
import { useDeleteChallenge } from 'hooks/useDeleteChallenge';
import { AnimalLoader } from 'components/loader';

interface ChallengeModalProps {
  challenge: Challenge;
  closeChallengeModal(): void;
}

export default function ChallengeModal({ challenge, closeChallengeModal }: ChallengeModalProps) {
  const [newChallenge, setNewChallenge] = useState(challenge);
  const [isConfirmDeleteDialogVisible, setIsConfirmDeleteDialogVisible] = useState(false);
  const { isLoading: isUpdatingChallenge, mutateAsync: updateChallenge } = useUpdateChallenge();
  const { isLoading: isDeletingChallenge, mutateAsync: deleteChallenge } = useDeleteChallenge();
  const navigate = useNavigate();

  const toastCtx = useContext(ToastContext);

  function onCancel() {
    closeChallengeModal();
  }

  function askDelete() {
    setIsConfirmDeleteDialogVisible(true);
  }

  async function onDelete(id: Challenge['id']) {
    deleteChallenge(id)
      .then(() => {
        closeChallengeModal();
        navigate('/');
      })
      .catch((err) => toastCtx.setError(err));

    setIsConfirmDeleteDialogVisible(false);
  }

  async function onSubmit() {
    updateChallenge(newChallenge)
      .then(closeChallengeModal)
      .catch((err) => toastCtx.setError(err));
  }

  function isLoading() {
    return isUpdatingChallenge || isDeletingChallenge || false;
  }

  function loadingText() {
    if (isUpdatingChallenge) {
      return 'The challenge is getting some upgrades...';
    } else if (isDeletingChallenge) {
      return 'The challenge is being deleted...';
    }

    return '';
  }

  const maxGoalValue = 999999;
  const rowWidth = 'medium';

  return (
    <Modal
      heading="Manage Challenge"
      onClose={!isLoading() ? onCancel : () => {}}
      closeModal={() => {
        closeChallengeModal();
      }}
      icon={<Tools size="large" />}
    >
      <ModalDivider />
      {isLoading() ? (
        <AnimalLoader text={loadingText()} />
      ) : (
        <>
          <Box alignContent="evenly" alignSelf="center" margin={{ top: 'small', bottom: 'small' }} width={rowWidth}>
            <Text color="nl-ash-0" margin={{ bottom: 'xsmall' }}>
              Title
            </Text>
            <Box round="small" background="white-1">
              <TextInput
                plain
                value={newChallenge.title}
                onChange={(event) => {
                  setNewChallenge({ ...newChallenge, title: event.target.value });
                }}
              />
            </Box>
          </Box>
          <Box alignSelf="center" width={rowWidth}>
            <Text color="nl-ash-0" margin={{ bottom: 'xsmall' }}>
              Duration
            </Text>
            <Box alignSelf="center" gap="small" direction="row" margin={{ bottom: 'small' }}>
              <Box background="white-1">
                <DateInput
                  calendarProps={{ size: 'small' }}
                  size="medium"
                  format="dd/mm/yyyy"
                  value={newChallenge.start.toString()}
                  onChange={(event) => {
                    const newValue = moment(event.value).toDate();
                    setNewChallenge({ ...newChallenge, start: newValue });
                  }}
                />
              </Box>
              <Text alignSelf="center">-</Text>
              <Box background="white-1">
                <DateInput
                  calendarProps={{ size: 'small' }}
                  size="medium"
                  format="dd/mm/yyyy"
                  value={newChallenge.end.toString()}
                  onChange={(event) => {
                    const newValue = moment(event.value).toDate();
                    setNewChallenge({ ...newChallenge, end: newValue });
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box alignSelf="center" margin={{ bottom: 'medium' }} width={rowWidth}>
            <Text color="nl-ash-0" margin={{ bottom: 'xsmall' }}>
              Target Score
            </Text>
            <Box alignSelf="center" round="small" background="white-1" width="100%">
              <TextInput
                min={0}
                max={maxGoalValue}
                plain
                type="number"
                value={newChallenge.goal}
                onChange={(event) => {
                  let goalValue = event.target.valueAsNumber;
                  if (goalValue > maxGoalValue) {
                    goalValue = maxGoalValue;
                  }
                  setNewChallenge({ ...newChallenge, goal: goalValue });
                }}
              />
            </Box>
            <ErrorMessage color="nl-ash-0">The target amount of points to achieve for each person</ErrorMessage>
          </Box>
          <ModalDivider />
          <ModalButtonContainer>
            <ModalButton content="Cancel" onClick={onCancel} />
            <ModalButton
              content="Save"
              onClick={async () => onSubmit()}
              disabled={isNaN(newChallenge.goal) || newChallenge.title.length === 0}
            />
            <ModalButton content="Delete" onClick={askDelete} />
          </ModalButtonContainer>

          {isConfirmDeleteDialogVisible && (
            <Dialog
              text={`Delete challenge '${challenge.title}'.`}
              disabled={isDeletingChallenge}
              onConfirm={async () => onDelete(challenge.id)}
              onCancel={() => {
                setIsConfirmDeleteDialogVisible(false);
              }}
            />
          )}
        </>
      )}
    </Modal>
  );
}
