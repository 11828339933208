import 'App.css';

import { Box, Button, ResponsiveContext, Text } from 'grommet';
import { Target } from 'grommet-icons';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppContext, ChallengeContext } from 'context/index';
import { daysUntil } from 'challenge/ChallengeUtils';
import { SubHeader } from 'components/subHeader';
import { ChallengeSearch } from 'components/challengeSearch';
import { FavoriteButton } from '../favorite';

export function AppBar() {
  const appCtx = useContext(AppContext);
  const challengeCtx = useContext(ChallengeContext);
  const location = useLocation();

  const challengeId = challengeCtx.challenge?.id ?? -1;

  const isFavorited = (): boolean => {
    if (appCtx.user?.favorites) {
      return appCtx.user?.favorites.find(({ id }) => id === challengeId) ? true : false;
    }
    return false;
  };

  return (
    <ResponsiveContext.Consumer>
      {(responsive) => (
        <Box
          responsive
          direction="row"
          as="header"
          pad={'small'}
          background="brand"
          height={{ min: '80px' }}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box fill={true}>
            <Link style={{ textDecoration: 'none' }} to="/">
              {responsive !== 'small' ? (
                <Text color="white" size="xlarge" weight="bold">
                  ChallengeApp
                </Text>
              ) : (
                <Box justify="start" direction="row">
                  <Text color="white" size="medium" weight="bold">
                    ChallengeApp
                  </Text>
                </Box>
              )}
            </Link>
          </Box>

          <Box fill={true}>
            {location.pathname === '/' ? (
              <ChallengeSearch />
            ) : (
              <Text color="white" alignSelf="center" truncate size="xlarge" weight="bold" margin="none">
                {appCtx.title}
              </Text>
            )}
            {challengeCtx.challenge && <SubHeader content={daysUntil(new Date(challengeCtx.challenge.end))} />}
          </Box>

          {location.pathname === '/' && (
            <Box fill={true} align="end">
              <Link to="/new-challenge" tabIndex={-1}>
                {responsive === 'small' ? (
                  <Button
                    a11yTitle=""
                    size="small"
                    primary
                    color="nl-lilac-600"
                    label={
                      <Text size="small" weight="bold" color="nl-ash-0">
                        Create Challenge
                      </Text>
                    }
                  />
                ) : (
                  <Button
                    a11yTitle=""
                    icon={<Target color="nl-ash-0" a11yTitle="" />}
                    size="large"
                    primary
                    color="nl-lilac-600"
                    label={
                      <Text size="small" weight="bold" color="nl-ash-0">
                        Create Challenge
                      </Text>
                    }
                  />
                )}
              </Link>
            </Box>
          )}

          {challengeCtx.challenge && (
            <>
              <Box fill={true}></Box>
              <FavoriteButton isFavorited={isFavorited()} challengeId={challengeId}></FavoriteButton>
            </>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}
