import React, { useEffect, useState } from 'react';
import { Box, Text, TextInput } from 'grommet';
import { ModalButton, ModalButtonContainer, ModalDivider } from 'components/modal';
import { ErrorMessage } from 'components/error';
import { AvatarSelector } from 'components/avatar';
import { Avatar, randomAvatar } from 'avatars/Avatars';
import { Challenge, Player } from 'types';
import { useGetPlayers } from 'hooks/useGetPlayers';

interface EditUserModalProps {
  challengeId: Challenge['id'];
  selectedPlayer: Player | undefined;
  onSubmit: (name: Player['name'], avatar: Avatar) => void;
  onCancel: () => void;
  onDelete?: () => void;
}

function isPlayerNameAlreadyInUse(players: Player[], name: Player['name']): boolean {
  return !!players.find((player) => player.name.toLowerCase() === name.toLowerCase());
}

export default function AddEditUserModal({
  challengeId,
  selectedPlayer,
  onSubmit,
  onCancel,
  onDelete,
}: EditUserModalProps) {
  const { data: players } = useGetPlayers(challengeId);

  const [newName, setNewName] = useState(selectedPlayer?.name || '');
  const [newAvatar, setNewAvatar] = useState(selectedPlayer?.avatar || randomAvatar());
  const [nameFormFieldInvalid, setNameFormFieldInvalid] = useState<boolean>(true);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');

  const maxNameLength = 30;

  useEffect(() => {
    function isNameFieldInvalid() {
      if (selectedPlayer?.name.toLowerCase() === newName.toLowerCase()) {
        setNameErrorMessage(``);
        return false;
      }
      if (newName.length === maxNameLength) {
        setNameErrorMessage(`Maximum character limit of ${maxNameLength} reached`);
        return true;
      }
      if (newName.length === 0) {
        setNameErrorMessage('');
        return true;
      }
      if (isPlayerNameAlreadyInUse(players ? players : [], newName)) {
        setNameErrorMessage(`Player name '${newName}' already used!`);
        return true;
      }
      setNameErrorMessage(``);
      return false;
    }

    setNameFormFieldInvalid(isNameFieldInvalid());
  }, [newName, players, selectedPlayer]);

  function clearData() {
    setNewName('');
    setNewAvatar(randomAvatar());
  }

  function handleSubmit() {
    onSubmit(newName, newAvatar);
    clearData();
  }

  function handleCancel() {
    onCancel();
    clearData();
  }

  return (
    <>
      <Box alignSelf="center" margin="medium" height={'30vh'}>
        <Text color="white" margin={{ bottom: 'small' }}>
          Name
        </Text>
        <Box round="small" background="white-1" width="medium">
          <TextInput
            plain
            maxLength={maxNameLength}
            value={newName}
            onChange={(event) => {
              setNewName(event.target.value);
            }}
            data-testid="user-name-input"
          />
        </Box>
        <ErrorMessage color="white">{nameErrorMessage}</ErrorMessage>
        <AvatarSelector currentAvatar={newAvatar} chooseAvatar={setNewAvatar}></AvatarSelector>
      </Box>
      <ModalDivider />
      <ModalButtonContainer>
        <ModalButton margin={{ horizontal: 'small' }} content="Cancel" onClick={handleCancel} />
        <ModalButton
          disabled={nameFormFieldInvalid}
          margin={{ horizontal: 'small' }}
          content={'Save'}
          onClick={handleSubmit}
        />
        {onDelete && (
          <ModalButton
            disabled={nameFormFieldInvalid}
            margin={{ horizontal: 'small' }}
            content={'Delete'}
            onClick={onDelete}
          />
        )}
      </ModalButtonContainer>
    </>
  );
}
