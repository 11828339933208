import { Box, Button, DataTable, Grommet, Heading, Layer, Text } from 'grommet';
import { Edit, Target, Trash, Hide, FormView, Close } from 'grommet-icons';
import React, { useContext, useState } from 'react';

import { ToastContext } from '../context';
import { Challenge, Quest } from 'types';
import { ModalTopIconHolder, ModalDivider, ModalButton, ModalButtonContainer, ModalTheme } from '../components/modal';
import { Dialog } from 'components/common/dialog';
import QuestForm from './QuestForm';
import { useUpdateQuest } from '../hooks/useUpdateQuest';
import { useGetQuests } from '../hooks/useGetQuests';
import { useRemoveQuest } from '../hooks/useRemoveQuest';
import { RunningAnimation } from 'challenge/ChallengeAnimations';
import { AvatarImage } from 'components/avatar';
import { randomAvatar } from 'avatars/Avatars';
import AppTheme, { CloseButton } from 'themes/AppTheme';

interface QuestModalProps {
  challengeId: Challenge['id'];
  closeQuestModal(): void;
}

export enum ViewState {
  QuestView,
  AddQuestView,
  EditQuestView,
  QuestIsBeingAddedView,
  QuestIsBeingEditedView,
}

export default function QuestModal({ challengeId, closeQuestModal }: QuestModalProps) {
  const [view, setView] = useState<ViewState>(ViewState.QuestView);
  const [isConfirmDeleteDialogVisible, setIsConfirmDeleteDialogVisible] = useState(false);
  const toastCtx = useContext(ToastContext);
  const [selectedQuest, setSelectedQuest] = useState<Quest>();
  const { isSuccess: isSuccessQuests, data: quests } = useGetQuests(challengeId);
  const { mutateAsync: updateQuest } = useUpdateQuest((err: Error) => toastCtx.setError(err));
  const { mutateAsync: removeQuest } = useRemoveQuest((err: Error) => toastCtx.setError(err));

  const handleToggle = (quest: Quest) => {
    const toggledQuest = { ...quest, active: !quest.active };

    updateQuest({ quest: toggledQuest });
  };

  const askDelete = (quest: Quest) => {
    setSelectedQuest(quest);
    setIsConfirmDeleteDialogVisible(true);
  };

  const onDelete = (id: Quest['id']) => {
    removeQuest({ questId: id });

    setIsConfirmDeleteDialogVisible(false);
  };

  const columns = [
    { property: 'title', header: 'Title' },
    { property: 'points', align: 'center' as 'center', header: 'Points' },
    {
      property: 'goal',
      align: 'center' as 'center',
      render: (datum) => {
        if (datum.goal === 0) {
          return '';
        }
        return datum.goal;
      },
      header: 'Goal',
    },
    {
      property: 'active',
      align: 'center' as 'center',
      header: 'Visible',
      render: (datum: Quest) => {
        if (datum.active) {
          return (
            <Button
              style={{ boxShadow: 'none' }}
              plain
              margin="small"
              a11yTitle="Toggle quest off"
              icon={<FormView size="large" />}
              onClick={() => handleToggle(datum)}
            />
          );
        }
        return (
          <Button
            style={{ boxShadow: 'none' }}
            margin="small"
            plain
            a11yTitle="Toggle quest on"
            icon={<Hide size="large" />}
            onClick={() => handleToggle(datum)}
          />
        );
      },
    },
    {
      property: 'id-edit',
      primary: true,
      header: 'Edit',
      align: 'center' as 'center',
      size: '100px',
      render: (datum) => (
        <Button
          style={{ boxShadow: 'none' }}
          margin="small"
          plain
          a11yTitle="Edit quest"
          icon={<Edit />}
          onClick={() => {
            setSelectedQuest(datum);
            setView(ViewState.EditQuestView);
          }}
        />
      ),
    },
    {
      property: 'id-remove',
      primary: true,
      header: 'Remove',
      align: 'center' as 'center',
      size: '100px',
      render: (quest: Quest) => {
        return (
          <Button
            style={{ boxShadow: 'none' }}
            margin="small"
            plain
            a11yTitle="Remove quest"
            icon={<Trash style={{ stroke: 'rgb(221, 70, 50)' }} />}
            onClick={() => {
              askDelete(quest);
            }}
          />
        );
      },
    },
  ];

  function renderContent(state: ViewState) {
    switch (state) {
      case ViewState.QuestView: {
        return (
          <>
            <Heading color="white-1" level="3" alignSelf="center" margin={{ bottom: 'small' }} data-testid="quest-view">
              Manage quests
            </Heading>
            <ModalDivider />
            <Box width="large" alignSelf="center" overflow="auto">
              <DataTable
                columns={columns}
                background={{
                  header: 'light-2',
                  body: ['light-1', 'white'],
                }}
                size="47vh"
                data={quests}
                step={10}
              />
            </Box>
            <ModalButtonContainer>
              <ModalButton
                content="Add Quest"
                onClick={() => {
                  setSelectedQuest(undefined);
                  setView(ViewState.AddQuestView);
                }}
              />
            </ModalButtonContainer>
          </>
        );
      }
      case ViewState.AddQuestView: {
        if (isSuccessQuests) {
          return (
            <QuestForm
              selectedQuest={undefined}
              quests={quests}
              challengeId={challengeId}
              loadForm={(newState: ViewState) => setView(newState)}
              closeForm={() => setView(ViewState.QuestView)}
              data-testid="add-quest-view"
            />
          );
        }
        return;
      }
      case ViewState.QuestIsBeingAddedView: {
        return (
          <>
            <Heading
              color="white-1"
              level="3"
              alignSelf="center"
              margin={{ bottom: 'small' }}
              data-testid="quest-being-added"
            >
              Add quest
            </Heading>
            <ModalDivider />
            <Text style={{ textAlign: 'center', margin: '20px 0' }} color="nl-ash-0" data-testid="craft-quest">
              A new quest is being crafted...
            </Text>
            <Box overflow="auto" wrap direction="row" flex justify="evenly" style={{ minHeight: '150px' }}>
              <RunningAnimation>
                <AvatarImage fit="contain" height="100px" cursor="pointer">
                  {randomAvatar()}
                </AvatarImage>
              </RunningAnimation>
              <RunningAnimation>
                <AvatarImage fit="contain" height="100px" cursor="pointer">
                  {randomAvatar()}
                </AvatarImage>
              </RunningAnimation>
              <RunningAnimation>
                <AvatarImage fit="contain" height="100px" cursor="pointer">
                  {randomAvatar()}
                </AvatarImage>
              </RunningAnimation>
            </Box>
          </>
        );
      }
      case ViewState.EditQuestView: {
        if (isSuccessQuests) {
          return (
            <QuestForm
              selectedQuest={selectedQuest}
              quests={quests}
              challengeId={challengeId}
              loadForm={(newState: ViewState) => setView(newState)}
              closeForm={() => setView(ViewState.QuestView)}
              data-testid="edit-quest-view"
            />
          );
        }
        return;
      }
      case ViewState.QuestIsBeingEditedView: {
        return (
          <>
            <Heading
              color="white-1"
              level="3"
              alignSelf="center"
              margin={{ bottom: 'small' }}
              data-testid="quest-being-edited"
            >
              Edit quest
            </Heading>
            <ModalDivider />
            <Text style={{ textAlign: 'center', margin: '20px 0' }} color={'nl-ash-0'}>
              The quest is getting some upgrades...
            </Text>
            <Box overflow="auto" wrap direction="row" flex justify="evenly" style={{ minHeight: '150px' }}>
              <RunningAnimation>
                <AvatarImage fit="contain" height="100px" cursor="pointer">
                  {randomAvatar()}
                </AvatarImage>
              </RunningAnimation>
              <RunningAnimation>
                <AvatarImage fit="contain" height="100px" cursor="pointer">
                  {randomAvatar()}
                </AvatarImage>
              </RunningAnimation>
              <RunningAnimation>
                <AvatarImage fit="contain" height="100px" cursor="pointer">
                  {randomAvatar()}
                </AvatarImage>
              </RunningAnimation>
            </Box>
          </>
        );
      }
    }
  }

  return (
    <Grommet theme={ModalTheme}>
      <Layer style={{ background: 'none' }} responsive={false}>
        <ModalTopIconHolder>
          <Target size="large" />
        </ModalTopIconHolder>
        <Box round="medium" style={{ minHeight: 'fit-content' }} background="nl-lilac-400" pad="large">
          <CloseButton style={{ textAlign: 'right' }} onClick={closeQuestModal} data-testid="quest-close-btn">
            <Close style={{ height: '35px', width: '35px', stroke: AppTheme.global.colors['nl-ash-0'] }} />
          </CloseButton>
          {renderContent(view)}
        </Box>

        {isConfirmDeleteDialogVisible && selectedQuest && (
          <Dialog
            text={`Delete quest '${selectedQuest.title}'. Previously collected points will not be removed.`}
            onConfirm={async () => onDelete(selectedQuest.id)}
            onCancel={() => {
              setIsConfirmDeleteDialogVisible(false);
            }}
          />
        )}
      </Layer>
    </Grommet>
  );
}
