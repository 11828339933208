import { Grommet, Button, ButtonProps } from 'grommet';
import React from 'react';

import { ButtonTheme } from './index';

interface Props extends ButtonProps {
  onClick: () => void;
}

export const CAButton = ({ label, primary, icon, disabled, active, a11yTitle, onClick }: Props) => {
  return (
    <Grommet theme={ButtonTheme}>
      <Button
        label={label}
        color="nl-ash-0"
        style={{
          minHeight: '46px',
          borderRadius: '15px',
        }}
        primary={primary}
        icon={icon}
        disabled={disabled}
        active={active}
        onClick={onClick}
        a11yTitle={a11yTitle}
      />
    </Grommet>
  );
};
