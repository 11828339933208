import { Box, Text } from 'grommet';
import React, { useState, useEffect } from 'react';
import { LinkNext, LinkPrevious } from 'grommet-icons';
import { Avatar, avatars } from 'avatars/Avatars';
import { AvatarImage } from './index';

interface AvatarSelectorProps {
  currentAvatar: Avatar;
  chooseAvatar(a: Avatar): void;
}

export const AvatarSelector = ({ currentAvatar, chooseAvatar }: AvatarSelectorProps) => {
  const [avatar, setAvatar] = useState(currentAvatar);
  const [indexOfCurrentAvatar, setIndexOfCurrentAvatar] = useState<number | undefined>();
  const avatarList = Object.keys(avatars);

  useEffect(() => {
    const index = Object.keys(avatars).indexOf(currentAvatar);
    setIndexOfCurrentAvatar(index + 1);
  }, [currentAvatar]);

  function nextAvatar() {
    const currentIndex = avatarList.indexOf(avatar);

    const avatarIsLast = currentIndex === avatarList.length - 1;
    const index = avatarIsLast ? 0 : currentIndex + 1;

    const newAvatar = avatarList[index] as Avatar;
    setAvatar(newAvatar);
    chooseAvatar(newAvatar);
  }

  function previousAvatar() {
    const currentIndex = avatarList.indexOf(avatar);

    const avatarIsFirst = currentIndex === 0;
    const index = avatarIsFirst ? avatarList.length - 1 : currentIndex - 1;

    const newAvatar = avatarList[index] as Avatar;
    setAvatar(newAvatar);
    chooseAvatar(newAvatar);
  }

  return (
    <>
      <Box direction="row" justify="center" align="center">
        <Box
          elevation="small"
          round="large"
          pad="small"
          background="white-1"
          data-testid="previous-avatar"
          onClick={() => previousAvatar()}
        >
          <LinkPrevious color="black" size="medium" />
        </Box>
        <AvatarImage fit="contain" dataTestId={`${avatar}-avatar`}>
          {avatar}
        </AvatarImage>
        <Box
          elevation="small"
          round="large"
          pad="small"
          background="white-1"
          data-testid="next-avatar"
          onClick={() => nextAvatar()}
        >
          <LinkNext color="black" size="medium" />
        </Box>
      </Box>
      <Box pad="small" direction="row" justify="center" align="center">
        <Text>
          {indexOfCurrentAvatar} / {avatarList.length}
        </Text>
      </Box>
    </>
  );
};
